import {
  Box, Button, Center,
  Flex, FormControl, FormLabel,
  Heading, HStack, Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent, ModalFooter,
  ModalOverlay,
  Text, Textarea, useToast,
  VStack
} from "@chakra-ui/react";
import { pxToRem } from "../../utils/editor.ts";
import { CheckIcon } from "@chakra-ui/icons";
import FileUpload from "./FileUpload.tsx";
import { StructureList, StructureListItem } from "./StructureList.tsx";
import React, { useState } from "react";
import { NewIntent } from "../../pages/Writer.tsx";
import { OutlineService } from "../../services";
import { ClientError } from "../../utils/clientError.ts";
import { PaperOutline } from "../../types/Writer/OutlineNode.ts";
import { PaperService } from "../../services/paperService.ts";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

interface CreatePaperProps {
  newIntent: NewIntent
  setNewIntent: (intent: NewIntent) => void
  isNewDocumentOpen: boolean
  onNewDocumentClose: () => void
}
/** create new document */
export const CreatePaper: React.FC<CreatePaperProps> = ({ newIntent, setNewIntent, isNewDocumentOpen, onNewDocumentClose }) => {
  const [paperTitle, setPaperTitle] = useState<string>('');
  const [writingArea, setWritingArea] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [structures, setStructures] = useState<StructureListItem[]>([
    {
      title: 'Your Structure',
      list: [],
      outline: {}
    },
    {
      title: 'Default Structure by  Doenba',
      list: [],
      outline: {}
    },
    {
      title: 'Reference Structure',
      list: [],
      outline: {}
    }
  ])
  const [currentStructure, setCurrentStructure] = useState<number>(0)
  const [showModalFooter, setShowModalFooter] = useState<boolean>(true)
  const [createDefaultLoading, setCreateDefaultLoading] = useState<boolean>(true)
  // next step disabled
  const [ nextDisabled, setNextDisabled ] = useState<boolean>(true)
  const [ uploadSuccess, setUploadSuccess ] = useState<boolean>(false)
  const [ startLoading, setStartLoading ] = useState<boolean>(false)
  // first step draft outline
  const [ draftOutline, setDraftOutline ] = useState<PaperOutline>(null)
  const { t } = useTranslation();
  const { user } = useAuth0();
  const navigate = useNavigate();
  const toast = useToast();

  /**
   * create paper
   * @param outline
   */
  const createPaperByOutline = async (outline: PaperOutline) => {
    try {
      const newPaper = await PaperService.createPaper({
        name: paperTitle ? paperTitle : 'Untitled',
        authors: user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name,
        outline: outline,
        abstract: '',
        keywords: '',
        draft: '',
        resources: [],
        toneReference: {}
      });
      navigate(`/editor/${newPaper.id}`);
    } catch (error) {
      new ClientError(error).toast();
    }
  }
  const onNewDocumentOnClose = () => {
    onNewDocumentClose();
    setPaperTitle('');
    setWritingArea('');
    setCurrentStep(1)
    setUploadSuccess(false)
  }

  const onDraftFinish = (outline: PaperOutline): void => {
    setNextDisabled(false)
    setDraftOutline(outline)
    setUploadSuccess(true)
    // Promise.resolve().then(() => {
    //   nextHandler()
    // })
  }
  const intentOnClick = (indent: NewIntent) => {
    setNewIntent(indent);
  }

  const updateStructureItem = (index: number, outline?: PaperOutline) => {
    const updatedStructures = [...structures];
    updatedStructures[index].list = outline?.nodes || []
    updatedStructures[index].outline = outline || {}
    setStructures([...updatedStructures])
  }
  /**
   * next handler
   */
  const nextHandler = async () => {
    if (currentStep === 1) {
      updateStructureItem(1)
      setCurrentStructure(0)
      if (newIntent === NewIntent.NO_DRAFT) {
        setCurrentStep(2)
        try {
          setCreateDefaultLoading(true)
          const res = await OutlineService.createDefaultOutlineByWrite({ writingArea })
          updateStructureItem(1, res.outline)
          setCreateDefaultLoading(false)
        } catch (error) {
          setCreateDefaultLoading(false)
          new ClientError(error).toast();
        }
      } else {
        if (draftOutline) {
          setCurrentStep(2)
          updateStructureItem(0, draftOutline)
          try {
            setCreateDefaultLoading(true)
            const res = await OutlineService.createDefaultOutlineByDraft({ outline: draftOutline })
            updateStructureItem(1, res.outline)
            setCreateDefaultLoading(false)
          } catch (error) {
            setCreateDefaultLoading(false)
            new ClientError(error).toast();
          }
        } else {
          toast({
            title: "Upload Draft",
            description: t(
              'upload-draft-tip'
            ),
            status: "warning",
            duration: 1000,
          })
        }
      }
    } else {
      // start
      try {
        setStartLoading(true)
        const res = await OutlineService.mergeOutline({
          outline: draftOutline || {},
          selectedOutline: structures[currentStructure].outline as PaperOutline
        })
        createPaperByOutline(res.outline)
      } catch (error) {
        setStartLoading(false)
        new ClientError(error).toast();
      }
    }
  }

  /**
   * after upload reference, update structure[2]
   */
  const afterReferenceUpload = (outline: PaperOutline) => {
    updateStructureItem(2, outline)
  }
  return (
    <Modal isOpen={isNewDocumentOpen} onClose={onNewDocumentOnClose} size={'lg'} closeOnOverlayClick={false}>
    <ModalOverlay bg={'whiteAlpha.700'}/>
    <ModalContent minW={'900px'} minHeight={"720px"} bg={'#FBFCFF'}
                  top={currentStep === 2 ? 0 : '5%'}
                  mt={currentStep === 2 ? '2rem' : '4rem'}
                  boxShadow={'none'}
                  border={'2px solid #D3D7E2'}
                  borderRadius={pxToRem(24)}>
      <Heading size={'md'} ml={6} mt={4} mb={1}>New Document</Heading>
      <ModalCloseButton _focusVisible={{ 'box-shadow': 'none' }}
                        bg={'#FFFFFF'}
                        width={pxToRem(24)}
                        height={pxToRem(24)}
                        _hover={{ bg: '#D3D7E2' }}
                        color={'#8E9ABC'}
                        top={pxToRem(20)}
                        right={pxToRem(20)}
                        fontSize={'8px'}
                        borderRadius={'50%'} />
      <ModalBody display={'flex'} p={8} bg={'#FBFCFF'} alignItems={'end'}>
        <Flex alignItems={'center'} width={'100%'}>
          { /** steps */}
          <Flex width={"150px"} height={'380px'} mt={12} flexDir={'column'} justify={'space-between'}
                alignItems={'center'} gap={4}>
            <VStack alignItems={"center"}>
              <Flex width={"20px"} height={"20px"} alignItems={"center"} justifyContent={"center"}
                    background={"#1D8AFF"} borderRadius={4}>
                <CheckIcon color={"#FFFFFF"} fontSize={12}/>
              </Flex>
              <Text fontWeight={"bold"} fontSize={14} color={"#333"}>
                Step 1
              </Text>
              <Text fontSize={"12px"} color={"#333"}>
                Choose States
              </Text>
            </VStack>
            <Box width={"2px"} flex={"1"} background={"#1D8AFF"}></Box>
            <VStack alignItems={"center"}>
              <Flex width={"20px"} height={"20px"} alignItems={"center"} justifyContent={"center"}
                    background={"#1D8AFF"} borderRadius={4}>
                {currentStep === 2 ? <CheckIcon color={"#FFFFFF"} fontSize={12}/>
                  : <Box bg={"#FFFFFF"} width={'10px'} height={'10px'} borderRadius={6}/>
                }
              </Flex>
              <Text fontWeight={"bold"} fontSize={14} color={"#333"}>
                Step 2
              </Text>
              <Text fontSize={"12px"} color={"#333"}>
                Select Structure
              </Text>
            </VStack>
          </Flex>
          {currentStep === 1 &&
              <Flex height={"450px"} flexDir={'column'} flex={1}
                    justifyContent={'start'} mt={'1rem'}>
                  <HStack justifyContent={"space-between"} alignItems={'center'} mb={10}>
                      <Center bg={newIntent === NewIntent.NO_DRAFT ? 'rgba(56,152,255,0.38)' : 'white'}
                              border={'1px solid #d3d7e2'} borderRadius={'12px'} mr={2} w={'45%'} cursor={'pointer'}
                              h={'176px'}
                              position={"relative"}
                              _hover={{ bg: 'rgba(56, 152, 255, 0.53)' }}
                              onClick={() => intentOnClick(NewIntent.NO_DRAFT)} fontSize={'14px'}>
                        {newIntent === NewIntent.NO_DRAFT &&
                            <Flex width={"26px"} height={"26px"}
                                  alignItems={"center"} justifyContent={"center"}
                                  background={"transparent"}
                                  border={"1px solid #1D8AFF"}
                                  position={"absolute"}
                                  right={'10px'}
                                  top={'10px'}
                                  borderRadius={6}>
                                <CheckIcon color={"#1D8AFF"} fontSize={12}/>
                            </Flex>
                        }
                          <Flex flexDir={'column'} alignItems={'center'}>
                              <Box mb={5}>
                                  <Image
                                      w={'100px'}
                                      objectFit='cover'
                                      src='/static/editor/edit.png'
                                      alt='no draft'
                                  />
                              </Box>
                              Start a New Document
                          </Flex>
                      </Center>
                      <Center
                          bg={newIntent === NewIntent.NORMAL_DRAFT ? 'rgba(56,152,255,0.38)' : 'white'}
                          border={'1px solid #d3d7e2'} borderRadius={'12px'} w={'45%'} cursor={'pointer'}
                          h={'176px'} position={'relative'}
                          _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                          onClick={() => intentOnClick(NewIntent.NORMAL_DRAFT)} fontSize={'14px'}>
                        {newIntent === NewIntent.NORMAL_DRAFT &&
                            <Flex width={"26px"} height={"26px"}
                                  alignItems={"center"} justifyContent={"center"}
                                  background={"transparent"}
                                  border={"1px solid #1D8AFF"}
                                  position={"absolute"}
                                  right={'10px'}
                                  top={'10px'}
                                  borderRadius={6}>
                                <CheckIcon color={"#1D8AFF"} fontSize={12}/>
                            </Flex>
                        }
                          <Flex flexDir={'column'} alignItems={'center'}>
                              <Box mb={5}>
                                  <Image
                                      w={'100px'}
                                      objectFit='cover'
                                      src='/static/editor/document-text.png'
                                      alt='well strucutred draft'
                                  />
                              </Box>
                            { t('import-draft-tip') }
                          </Flex>
                      </Center>
                  </HStack>
                {newIntent === NewIntent.NO_DRAFT && (
                  <FormControl>
                    <FormLabel>
                      <Flex flexDir={'row'} alignItems={'center'}>
                        What is your writing area?
                        {/*<Text ml={1} color='red'>*</Text>*/}
                      </Flex>
                    </FormLabel>
                    <Textarea
                      rows={8}
                      bg={'white'}
                      value={writingArea}
                      borderRadius={'12px'}
                      onChange={(e) => {
                        setWritingArea(e.target.value)
                        if (e.target.value) {
                          setNextDisabled(false)
                        }
                      }}
                    />
                  </FormControl>
                )}
                {newIntent === NewIntent.NORMAL_DRAFT && (
                  uploadSuccess ?  <Flex borderRadius={8}
                                         py={"48px"}
                                         border={ "1px solid #C9D8E7" }
                                         background={"#FFFFFF"}
                                         flexDir={'column'}
                                         justifyContent={"center"}
                                         alignItems="center">
                      <Image
                        w={"80px"}
                        objectFit="cover"
                        src="/static/editor/check_ok.png"
                      />
                      <Text
                        mt={2}
                        fontSize={12}
                        color="gray.400"
                        lineHeight="12px"
                        textAlign="center"
                      >
                        Please Click Next to Continue
                      </Text>
                    </Flex>:
                    <FileUpload usage={'draft'} border={true} afterUpload={onDraftFinish} />
                )
                }
              </Flex>
          }
          {currentStep === 2 &&
              <Flex width={'100%'} height={pxToRem(740)} flexDir={'column'}
                    justifyContent={'space-around'} gap={2}>
                {structures.map((structure, index) => {
                  return (
                    <Box key={index}
                         height={'33%'}
                         bg={currentStructure === index ? 'rgba(56, 152, 255, 0.38)' : '#FBFCFF'}
                         border={'1px solid'}
                         borderColor={currentStructure === index ? 'rgba(56,152,255,0.38)' : '#d3d7e2'}
                         borderRadius={'12px'} p={4}>
                      <StructureList
                        title={`Option${index + 1}: ${structure.title}`}
                        list={structure.list}
                        index={index}
                        currentIndex={currentStructure}
                        createDefaultLoading={createDefaultLoading}
                        setCurrentIndex={setCurrentStructure}
                        setShowModalFooter={setShowModalFooter}
                        afterReferenceUpload={afterReferenceUpload}
                      ></StructureList>
                    </Box>
                  )
                })}
              </Flex>
          }
        </Flex>
      </ModalBody>
      {showModalFooter && <ModalFooter justifyContent={'space-around'} position={'relative'}>
        {currentStep > 1 &&
            <Image position={'absolute'} left={'5rem'}
                   onClick={() => setCurrentStep(1)}
                   cursor={'pointer'}
                   mt={4}
                   w={pxToRem(32)} src="/static/editor/back.svg" alt="back button" />
        }
          <Button
              w="120px"
              mt={4}
              bg={currentStep === 1 ? '#FBFCFF' : '#1D8AFF'}
              _hover={{ background: currentStep === 1 ? '#FBFCFF' : '#1D8AFF' }}
              border={'1px solid #1D8AFF'}
              disabled={nextDisabled}
              isLoading={startLoading}
              borderRadius={pxToRem(12)}
              color={currentStep === 1 ? '#1D8AFF' : '#FFFFFF'}
              onClick={nextHandler}>
            { currentStep === 1 ? 'Next' : 'Start' }
          </Button>
      </ModalFooter>
      }
    </ModalContent>
  </Modal>
  )
}