import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { sameNameNodes, setCursorPosition } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { find } from "lodash";

/**
 * editor add/del/edit outline handler
 */
export const useEditorHook = () => {
  const { nextId, setNextId, nodeList, setOutlineIsChange } = useOutlineStore();

  /**
   * add new node
   * @param node
   * @param type section | paragraph
   * @param level
   * @param content newNode text
   */
  const addNewNode = (
    node: PaperOutlineNode,
    type: OutlineNodeType,
    level: number,
    content?: string
  ) => {
    const newNode: PaperOutlineNode = {
      id: nextId,
      name: '',
      type: type,
      level,
    };
    switch (type) {
      case OutlineNodeType.Section: {
        // todo: 优化parentId设置
        if (level === node.level + 1) {
          newNode.parentId = node.id;
        }
        if (level === node.level) {
          newNode.parentId = node.parentId;
        }
        if (level === node.level - 1) {
          const parent = find(nodeList, { id:node.parentId })
          newNode.parentId = parent.parentId;
        }
        newNode.placeholder = content ? content : ("New Heading" + level)
        // todo: placeholder时，不存在同名name,label是否也不考虑了
        // newNode.name = content ? content : ("New Heading" + level)
        // newNode.label = newNode.name.toLowerCase()
        // const sameLen = sameNameNodes(newNode.name, nodeList)
        // sameLen && (newNode.label += `_${sameLen}`)
        newNode.children = []
        break
      }
      case OutlineNodeType.Paragraph: {
        newNode.parentId = node.type === OutlineNodeType.Section ? node.id : node.parentId
        newNode.content = content || ''
        delete newNode.name
        break
      }
    }
    if (node.children == undefined) node.children = []
    setOutlineIsChange(true)
    // todo: 添加删除时需要更新下nodeList的content,没保存之前会有编辑
    // todo: enter add error after syncDomToNodes
    // syncDOMToNodes(nodeList);
    const index = nodeList.findIndex(item => item.id === node.id)
    if (node.type === OutlineNodeType.Section) {
      nodeList[index].name = node.name
    } else {
      nodeList[index].content = node.content
    }
    let subIndex = index
    for (let i = index + 1; i <= nodeList.length; i++) {
      if (i === nodeList.length) { // 当前行有子subsection，是最后一个subsection
        subIndex = i
        break
      } else {
        if (nodeList[i].level <= nodeList[index].level) {
          subIndex = i
          break
        }
      }
    }

    if (subIndex === index) {
      nodeList.splice(index + 1, 0, newNode)
    } else {
      nodeList.splice(subIndex, 0, newNode)
    }
    setNextId(newNode.id + 1)
    Promise.resolve().then(() => {
      const newDomNode = document.querySelector(`li[data-id="li-${newNode.id}"] .editable-textarea`)
      if (newDomNode) {
        setCursorPosition(newDomNode, content ? 'start' : 'end')
      }
    })
  }

  /**
   * 全量从DOM中读取文本并更新 outline 的内容
   * @param nodes 大纲节点数组
   */
  const syncDOMToNodes = (nodes: PaperOutlineNode[])=> {
    nodes.forEach((node) => {
      const domEl = document.querySelector(
        `p[data-id="${node.id}-${node.level}"]`
      ) as HTMLElement | null;
      // 如果找到 DOM，回写文本内容
      if (domEl) {
        if (node.type === OutlineNodeType.Section) {
          if (node.name !== domEl.textContent) {
            setOutlineIsChange(true)
            node.name = domEl.textContent ?? "";
            node.label = node.name
            const sameLen = sameNameNodes(node.name, nodeList)
            if (sameLen) {
              node.label += `_${sameLen}`
            }
          }
        } else if (node.type === OutlineNodeType.Paragraph) {
          if (node.content !== domEl.textContent) {
            setOutlineIsChange(true)
            node.content = domEl.textContent ?? "";
          }
        }
      }

      if (node.children?.length) {
        syncDOMToNodes(node.children)
      }
    });
  }

  return {
    addNewNode,
    syncDOMToNodes
  }
}