import React, { useState } from "react";
import {
  Accordion,
  AccordionButton, AccordionIcon,
  AccordionItem, AccordionPanel,
  Box,
  Button, Center,
  HStack,
  IconButton,
  Image,
  Text, useToast,
  VStack
} from "@chakra-ui/react";
import { pxToRem } from "../../../utils/editor.ts";
import { PaperNodeSuggestion } from "../../../types/Writer/PaperNodeSuggestion.ts";
import { useTranslation } from "react-i18next";
import { PaperService } from "../../../services/paperService.ts";
import { filter } from "lodash";
import { ClientError } from "../../../utils/clientError.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";

interface SuggestionProps {
  isCalculated: boolean
  setIsCalculated: (isCalculated: boolean) => void
}

export const Suggestion: React.FC<SuggestionProps> = (
  {
    isCalculated,
    setIsCalculated,
  }
) => {
  const { t } = useTranslation()
  const { outline, currentSectionIndex } = useOutlineStore()
  const [aiDataCount, setAIDataCount] = useState<number>(0);
  const [isError, setIsError] = useState<boolean>(false);
  const toast = useToast();
  const [resultList, setResultList] = useState<PaperNodeSuggestion[]>([])
  const [allResolved, setAllResolved] = useState<boolean>(false)
  /**
   * review suggestion init
   */
  const reviewSection = async () => {
    setIsCalculated(true);
    // const id = outline.nodes[0].id;
    try {
      const newSuggestions = await PaperService.suggestion(
        outline.nodes[currentSectionIndex],
        suggestionDegreeValue
      );
      if (newSuggestions?.review_aspect?.length) {
        const isOk = newSuggestions.review_aspect.every((item) => item.score === 3)
        if (isOk) {
          setResultList([newSuggestions.review_aspect[0]])
          setAllResolved(true)
        } else {
          const list = filter(newSuggestions.review_aspect, item => item.score !== 3)
          setResultList(list)
          if (aiDataCount === 0) {
            setAIDataCount(list.length)
          }
        }
      }
      setIsCalculated(false);
    } catch (error) {
      setIsCalculated(false);
      new ClientError(error).toast();
    }
    // saveAIData(id, AIDataType.suggestion, aiData);
  };
  const SuggestionDegreeMap = [
    t("editor-editormodel-AI-SuggestionDegree-0"),
    t("editor-editormodel-AI-SuggestionDegree-1"),
    t("editor-editormodel-AI-SuggestionDegree-2"),
    t("editor-editormodel-AI-SuggestionDegree-3"),
    t("editor-editormodel-AI-SuggestionDegree-4"),
  ];
  const degreePositions = [30, 70, 120, 140, 180]

  const [suggestionDegreeValue, setSuggestionDegreeValue] = useState<string>(
    SuggestionDegreeMap[2]
  );

  /**
   * check suggestion
   */
  const checkSuggestion = async (index: number) => {
    resultList[index].checking = true
    setResultList([...resultList])
    try {
      resultList[index] = await PaperService.sectionSuggestion({
        sectionNode: outline.nodes[currentSectionIndex],
        suggestion_data: resultList[index],
        suggestion_level: suggestionDegreeValue
      });
      resultList[index].checking = false
      setResultList([...resultList])

      if (resultList[index].score === 3) {
        if (resultList.length === 1) {
          setAllResolved(true)
        }
        setTimeout(() => {
          const data = resultList.concat([]);
          data.splice(index, 1);
          setResultList(data)
        }, 3000)
      }
      setIsCalculated(false);
      setIsError(false);
    } catch (error) {
      setIsCalculated(false);
      setIsError(true);
      new ClientError(error).toast();
    }
  }

  return (
    <VStack width={pxToRem(272)}>
        <Box
          mt={2}
          width={"100%"}
          paddingLeft={
            degreePositions[SuggestionDegreeMap.indexOf(suggestionDegreeValue)]
          }
        >
          <Text
            aria-multiline={false}
            display="inline-block"
            pl={1}
            pr={1}
            border={"1px solid #ccc"}
            bg={'#FFFFFF'}
            borderRadius={"3px"}
          >
            {suggestionDegreeValue}
          </Text>
        </Box>
        <HStack
          border={"1px solid #ccc"}
          borderRadius={"0.5rem"}
          paddingRight={1}
          bg={'#FFFFFF'}
          width={'100%'}
        >
          <Box
            borderRight={"1px solid #ccc"}
            borderRadius={"0.5rem"}
            bg={'#FFFFFF'}
          >
            <Image
              m={2}
              w={pxToRem(24)}
              objectFit="cover"
              src="/static/ToolAI/degree.png"
            />
          </Box>
          {SuggestionDegreeMap.map((item) => (
            <IconButton
              key={item}
              bg={item == suggestionDegreeValue ? "#3898FF61" : "transparent"}
              size={"sm"}
              border={
                item == suggestionDegreeValue
                  ? "1px solid #3898FF"
                  : undefined
              }
              borderRadius={"10px"}
              icon={
                <Image
                  w={item == suggestionDegreeValue ? pxToRem(24) : "6px"}
                  objectFit="cover"
                  src={
                    "/static/ToolAI/" +
                    (item == suggestionDegreeValue ? "degree.png" : "dot.png")
                  }
                />
              }
              aria-label={""}
              onClick={() => {
                setSuggestionDegreeValue(item);
              }}
            />
          ))}
        </HStack>
        <Button
          marginLeft={pxToRem(20)}
          width={pxToRem(78)}
          height={pxToRem(34)}
          bgColor={"transparent"}
          border={"1px solid #1D8AFF"}
          textColor={"#1D8AFF"}
          fontSize={"14px"}
          _hover={{ bg: '#1D8AFF', color: '#FFF' }}
          onClick={reviewSection}
        >
          {t("editor-editormodel-AI-Review")}
        </Button>

      {isCalculated ? (
        <VStack
          width={pxToRem(272)}
          height={pxToRem(82)}
          border={"1px solid #D3D7E2"}
          borderRadius={"12px"}
          bgColor={"#FFFFFF"}
        >
          <HStack paddingTop={pxToRem(20)}>
            <Image
              w={pxToRem(28)}
              position={"relative"}
              left={"-8px"}
              objectFit="cover"
              src="/static/loading-animate.svg"
              transform={"scale(2)"}
            />
            <Text textColor={"#8E9ABC"} fontSize={"1rem"}>
              {t("editor-editormodel-AI-SuggestionsCalculate")}
            </Text>
          </HStack>
          <IconButton
            pl={pxToRem(230)}
            bgColor={"transparent"}
            _hover={{ bgColor: "transparent" }}
            icon={
              <Image
                w={pxToRem(24)}
                objectFit="cover"
                src={
                  "/static/ToolAI/" +
                  (isError ? "recalculate.svg" : "cancelCal.svg")
                }
                alt="cancel"
              />
            }
            onClick={() => {
              setIsCalculated(false);
              setIsError(false);
            }}
            aria-label=""
          />
        </VStack>
      ) : (
        <Box>
          {(resultList.length || allResolved) && <Center
            width={pxToRem(272)}
            height={pxToRem(64)}
            bgColor={"#FFFFFF"}
            mt={'0.4rem'}
            mb={'1.3rem'}
            border={"1px solid #D3D7E2"}
            borderRadius={"12px"}
            textColor={"#8E9ABC"}
          >
            {!allResolved && (
              <HStack gap={0} color={'#8E9ABC'} fontSize={'1rem'} fontFamily={'Lato'}>
                <Text fontWeight={'bold'} color={'#1D8AFF'}>{aiDataCount - resultList.length}</Text>
                <Text fontWeight={'bold'}>/{aiDataCount}</Text>
                <Text> &nbsp;{t("editor-editormodel-AI-SuggestionsContents")}</Text>
              </HStack>
            )}
            {allResolved && <Text color={'#1D8AFF'} fontSize={'1rem'}
                                  fontFamily={'Lato'}
                                  textAlign={'center'}
                                  dangerouslySetInnerHTML={ { __html: t('suggestion-all-ok') } }
            ></Text>}
          </Center>
          }
          <Accordion
            allowMultiple
            defaultIndex={Array.from(
              { length: resultList.length },
              (_, i) => i
            )}>
            {resultList.map((content: PaperNodeSuggestion, index) => (
              <AccordionItem
                mt={2}
                width={pxToRem(272)}
                key={index.toString()}
                border={"1px solid #D3D7E2"}
                borderRadius={"12px"}
                p={2}
                bgColor={"#FFFFFF"}
              >
                <VStack>
                  <HStack>
                    <Image
                      w={pxToRem(58)}
                      h={"1rem"}
                      objectFit="cover"
                      src={"/static/doenba-logo-blue.png"}
                      mr={pxToRem(130)}
                    />
                    <AccordionButton>
                      <AccordionIcon/>
                    </AccordionButton>
                  </HStack>
                  <AccordionPanel py={pxToRem(20)}>
                    {!content.checking && content.score !== 3 && <>
                        <Text
                            fontSize={"14px"}
                            fontWeight={400}
                            width={pxToRem(248)}
                            aria-multiline={true}
                        >
                          {content.review}
                        </Text>
                        <Text
                            fontSize={"14px"}
                            fontWeight={700}
                            width={pxToRem(248)}
                            aria-multiline={true}
                        >
                          {content.suggestion}
                        </Text>
                        <HStack mt={pxToRem(20)}>
                            <IconButton
                                size={"sm"}
                                bg="transparent"
                                icon={
                                  <Image
                                    w={pxToRem(20)}
                                    objectFit="cover"
                                    src={"/static/ToolAI/useContent.png"}
                                  />
                                }
                                aria-label="copy"
                                onClick={() => {
                                  navigator.clipboard
                                    .writeText(content.suggestion)
                                    .then(() => {
                                      toast({
                                        title: "Document",
                                        description: t(
                                          "editor-editormodel-copytext"
                                        ),
                                        status: "success",
                                        duration: 1000,
                                      });
                                    })
                                    .catch((err) => {
                                      console.error("Failed to copy: ", err);
                                    });
                                }}
                            />
                            <Button
                                width={pxToRem(100)}
                                height={pxToRem(34)}
                                fontSize={14}
                                textColor={"#1D8AFF"}
                                border={"1px solid #1D8AFF"}
                                bgColor={"transparent"}
                                onClick={() => {
                                  checkSuggestion(index)
                                }}
                            >
                              {t("editor-editormodel-AI-Suggestion-Check")}
                            </Button>
                            <Button
                                width={"100px"}
                                height={pxToRem(34)}
                                bgColor={"#EA5050"}
                                fontSize={14}
                                textColor={"#FFFFFF"}
                                _hover={{ bgColor: "#EA5050" }}
                                onClick={() => {
                                  const data = resultList.concat([]);
                                  data.splice(index, 1);
                                  setAIDataCount(data.length);
                                  setResultList(data);
                                }}
                            >
                              {t("editor-editormodel-AI-Suggestion-Ignore")}
                            </Button>
                        </HStack>
                    </>}
                    {content.checking && <>
                        <VStack paddingTop={pxToRem(20)}>
                            <Image
                                w={pxToRem(100)}
                                objectFit="cover"
                                src="/static/loading-animate.svg"
                                transform={"scale(1.2)"}
                            />
                            <Text textColor={"#8E9ABC"} fontSize={"1rem"}>
                              {t("editor-editormodel-AI-Suggestion-Checking")}
                            </Text>
                        </VStack>
                    </>}
                    {content.score === 3 && <>
                        <VStack paddingTop={pxToRem(20)}>
                            <Image
                                w={pxToRem(80)}
                                objectFit="cover"
                                src="/static/editor/check_ok.png"
                            />
                            <Text textColor={"#000"} fontSize={"1rem"} whiteSpace="nowrap"
                                  transform={"scale(0.9)"}>
                              {t("editor-editormodel-AI-Suggestion-Check-Ok")}
                            </Text>
                        </VStack>
                    </>}
                  </AccordionPanel>
                </VStack>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
      )}
    </VStack>
  )
}