/**
 * find current edit node index
 */
import { OutlineNodeType, PaperOutlineNode } from "../types/Writer/OutlineNode.ts";
import { each, findIndex } from "lodash";

export const getSelectNodeIndex = (nodeList: PaperOutlineNode[], editDom: HTMLElement | Element) => {
  const dataId = editDom?.getAttribute('data-id');
  return nodeList.findIndex(
    child => `${child.id.toString()}-${child.level.toString()}` === dataId
  );
}

/**
 * px to rem
 */
export const pxToRem = (px: number) => {
  return px / 16 + 'rem';
}

/**
 * deep find outline node
 */
export const findNodeByIds = (sectionNodes: PaperOutlineNode[], ids: number[]): PaperOutlineNode[] => {
  const result = []
  const idSet = new Set(ids)

  function recursiveSearch(nodes: PaperOutlineNode[]) {
    for (const node of nodes) {
      if (idSet.has(node.id)) {
        result.push(node)
      }
      if (node.children && node.children.length > 0) {
        recursiveSearch(node.children)
      }
    }
  }

  recursiveSearch(sectionNodes)
  return result
}

/**
 * deep outline remove node
 * @param node
 * @param removeNode
 */
export const deleteNode = (node: PaperOutlineNode, removeNode: PaperOutlineNode) => {
  if (node.children?.length) {
    node.children = node.children.filter((item: PaperOutlineNode) => item.id !== removeNode.id)
    each(node.children, (item: PaperOutlineNode) => {
      deleteNode(item, removeNode)
    })
  }
}

/**
 * 删除多行内容处理
 */
  // const deleteMultiNode = (outline: PaperOutline, nodeList: PaperOutlineNode[], startIndex: number, endIndex: number) => {
  //   for (let i = endIndex - 1; i > startIndex; i--) { // 删除中间行
  //     deleteNode(outline.nodes[0], nodeList[i])
  //   }
  //   // 删除startIndex行被选中的内容
  // }

export const getNodesWordsCount = (nodes: PaperOutlineNode[]) => {
    if (!nodes) return 0;
    let wordsCount = 0;
    nodes.forEach((node) => {
      if (!node) return;
      const str = node.type == OutlineNodeType.Paragraph ? node.content : node.name;
      if (str) {
        str.replace("\n", " ");
        wordsCount += str.trim().length
      }
    });
    return wordsCount;
  }

/**
 * set cursor position
 * @param node target cursor
 * @param position
 */
export const setCursorPosition = (node: Node, position?: 'start' | 'end' | number) => {
  const range = document.createRange();
  range.selectNodeContents(node);
  if (position !== 'start' && position !== 'end' && node?.firstChild) {
    range.setStart(node?.firstChild, position)
    range.collapse(true)
  } else {
    position === 'start' ? range.collapse(true) : range.collapse(false)
  }
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);
}

/**
 * check if the node label is unique
 */
export const sameNameNodes = (name: string, nodeList: PaperOutlineNode[]) => {
  const fileNodes = nodeList.filter((node) => node.name === name)
  let maxNum = 0
  fileNodes.forEach(node => {
    if (node.label) {
      const splits = node.label.split('_')
      const num = parseInt(splits[splits.length - 1])
      if (!isNaN(num) && maxNum < num) {
        maxNum = num
      }
    }
  })
  return fileNodes?.length === 0 ? 0 : (maxNum + 1)
}

/**
 * 获取当前编辑的li，包括其属性
 */
export const getCurrentEditLi = (startContainer: Node, nodeList: PaperOutlineNode[]) => {
  let editDom, id, type, index
  if (startContainer.nodeType === Node.TEXT_NODE) {
    editDom = startContainer.parentElement.closest('li')
  } else {
    editDom = (startContainer as HTMLElement).closest('li')
  }
  if (editDom) {
    id = editDom.getAttribute('data-id')?.split('-')[1]
    type = editDom.getAttribute('data-type')
    index = findIndex(nodeList, { id: Number(id) })
  }
  return {
    editDom,
    id,
    type,
    index
  }
}

export const isEmptyNode = (node: HTMLElement) => {
  return node.innerHTML.replace(/<br\s*\/?>/gi, '').trim() === ''
}