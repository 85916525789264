import { useEffect, useState, useRef } from "react";
import {
    Box, Wrap, Flex, Text,
    useDisclosure, useToast,
    Input, VStack, Card, 
    // IconButton, Table, TableContainer, Tbody, Td, Th, Thead, Tr,
    Center,
    WrapItem,
    Button
} from "@chakra-ui/react";
import { FileService } from "../services/fileService";
import FileCard from "../components/FileCard";
import { 
    // ViewIcon, DownloadIcon, DeleteIcon, 
    SmallAddIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
// import { MdFileUpload } from "react-icons/md";
// import { MdGridView } from "react-icons/md";
// import { FaList } from "react-icons/fa";
// import { dateToString } from "../services/dateTimeConversion";
import { useSetRecoilState } from "recoil";
import { appLoadingState, appLoadingText, articlePreviewModalOpenStatus, duplicatedPendingArticleId, holdingForUploadingFiles } from "../atoms/rootAtom";
import { UserFileDataService } from "../services/userFileDataService";
import FilePreviewModal from "../components/MyDrive/FilePreviewModal";
import MiniNav from "../components/NavBar/MiniNav";
import { ClientError } from "../utils/clientError";
import useUnprocessedFileHandler from "../hooks/useUnprocessedFileHandler";
import { FileDirectoryService } from "../services";
import { Directory } from "../types/Directory";
import DirectoryCard from "../components/MyDrive/DirectoryCard";
import CreateDirectoryModal from "../components/MyDrive/CreateDirectoryModal";
import useDirectories from "../hooks/useDirectories";
import { useLocation, useNavigate } from "react-router-dom";
import DirectoryBreadCrumb from "../components/DirectoryBreadCrumb/DirectoryBreadCrumb";
import DraggableBox from "../components/DnD/DraggableBox";
import DroppableArea from "../components/DnD/DroppableArea";
import CuttingFileToast from "../components/CuttingFileToast/CuttingFileToast";

const MyDrive = () => {
    const { t } = useTranslation();
    const [isDragging, setIsDragging] = useState(false);
    const { 
        currentDirectory,
        currentDirectoryId,
        addEditDirectoryInState,
        setCurrentDirectoryId,
        setChildDirectoriesInCurrentDirectory,
        setFilesInCurrentDirectory,
    } = useDirectories();
    const [hasDirectoryDeeplinkCheckCompleted, setHasDirectoryDeeplinkCheckCompleted] = useState<boolean>(false);
    const { isOpen: isFilePreviewModalOpen, onOpen: onFilePreviewModalOpen, onClose: onFilePreviewModalClose } = useDisclosure();
    const { isOpen: isCreateDirectoryModalOpen, onOpen: onCreateDirectoryModalOpen, onClose: onCreateDirectoryModalClose } = useDisclosure();
    const [viewMode] = useState<string>('grid');
    const setAppLoadingState = useSetRecoilState(appLoadingState);
    const setLoadingText = useSetRecoilState(appLoadingText);
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    const { addNeededFilesToPoller } = useUnprocessedFileHandler();

    const uploadRef = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedArticleHtml, setSelectedArticleHtml] = useState<string>('');
    const setAriclePreviewModalOpenStatus = useSetRecoilState(articlePreviewModalOpenStatus);
    const setOnHoldArtilces = useSetRecoilState(holdingForUploadingFiles);
    const setduplicatedPendingArticleId = useSetRecoilState(duplicatedPendingArticleId);

    const [selectedFile, setSelectedFile] = useState<File | null>(null);

    useEffect(() => {
        if (hasDirectoryDeeplinkCheckCompleted) {
            fetchDirectoryContents();
        }
    }, [currentDirectoryId, hasDirectoryDeeplinkCheckCompleted]);

    // Set currentDirectoryId based on the URL when the component mounts or the URL changes
    useEffect(() => {
        async function getAndSetDirectory(directoryId) {
            try {
                const directoryContents = await FileDirectoryService.getDirectoryContents(directoryId);
                setCurrentDirectoryId(directoryContents.id);
                addEditDirectoryInState(directoryContents);
            } catch {
                new ClientError(
                    new Error('Directory was not found or you do not have permission to access it.')
                ).toast();
                
                setCurrentDirectoryId(null);
            } finally {
                setHasDirectoryDeeplinkCheckCompleted(true);
            }
        }

        const params = new URLSearchParams(location.search);
        const directoryId = params.get('directoryId');

        if (directoryId) {
            getAndSetDirectory(directoryId);
        } else {
            setCurrentDirectoryId(null);
            setHasDirectoryDeeplinkCheckCompleted(true);
        }
    }, [location.search]);

     // Update the URL when currentDirectoryId changes
     useEffect(() => {
        const params = new URLSearchParams(location.search);
        const currentUrlDirectoryId = params.get('directoryId');

        if (currentDirectoryId?.toString() !== currentUrlDirectoryId) {
            if (currentDirectoryId === null) {
                params.delete('directoryId');
            } else {
                params.set('directoryId', currentDirectoryId.toString());
            }

            navigate(`${location.pathname}?${params.toString()}`);
        }
    }, [currentDirectoryId, navigate, location.pathname]);

    const fetchDirectoryContents = async () => {
        try {
            const directoryContents = await FileDirectoryService.getDirectoryContents(currentDirectoryId);
            addEditDirectoryInState(directoryContents);
        } catch (error) {
            new ClientError(error).toast();
        }
    }

    // Effect hook to run after selectedFile changes
    useEffect(() => {
        if (selectedFile) {
            handleFileUpload();
        }
    }, [selectedFile]); 

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        setIsDragging(true)
    }

    const handleDragLeave = () => {
        setIsDragging(false);
    }

    const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);

        // Assuming the files are in DataTransfer object
        const files = event.dataTransfer.files;
        
        // Check if more than one file was dropped, if so, alert and ignore extra files
        if (files.length > 1) {
            alert("Please drop only one file.");
            return;  // Skip processing further
        }

        // Assuming handleFileChange processes the file
        if (files.length === 1) {
            uploadFiles(Array.from(files));
        }
    }

    const uploadFiles = async (files: File[]) => {
        setAppLoadingState(true);
        setLoadingText('File uploading');

        try {
            if (files.length > 1) {
                throw new Error('One artilce is allowed at this moment.');  
            }

            for (const file of files) {
                const foundExists = await getHashValue(file);
                if (foundExists.isDuplicate) {
                    setOnHoldArtilces([file]);
                    setduplicatedPendingArticleId(foundExists.existingFileId);
                    setAriclePreviewModalOpenStatus(true);
                } else {
                    const response = await FileService.uploadFiles([file], currentDirectoryId);
                    const responseFile = response[0];
                    if (responseFile.isSuccessful) {
                        addNeededFilesToPoller(responseFile.file);
                    } else {
                        let message = "Error uploading file: " + responseFile.identifier;
                        if (responseFile.error && typeof responseFile.error === 'string') {
                            message += ". " + responseFile.error;
                        }
                        toast({
                            title: "File Upload Error",
                            description: message,
                            status: "error",
                        });
                    }
                }
            }
            fetchDirectoryContents();
            setSelectedFile(null);
        } catch (error) {
            new ClientError(error).toast();
        } finally {
            setAppLoadingState(false);
        }
    }

    const fetchSelectedArticle = async (id: string) => {
        try {
            const html = await FileService.downloadHtmlFile(id);
            setSelectedArticleHtml(html);
            setIsLoading(false);
            onFilePreviewModalOpen();
        } catch (err) {
            console.log('Fetching article error: ', err);
            setIsLoading(false);
        }
    }

    async function handleFileDelete(id: string) {
        const oldFiles = [...currentDirectory.files];
        
        try {
            setFilesInCurrentDirectory((prevFiles) => prevFiles.filter((file) => file.id !== id));
            await UserFileDataService.toggleIsInDrive(id);
        } catch (e) {
            new ClientError(e).toast();
            setFilesInCurrentDirectory(oldFiles);
        }
    }

    async function handleDirectoryDelete(id: number) {
        const oldChildDirectories = [...currentDirectory.childDirectories];

        try {
            setChildDirectoriesInCurrentDirectory((prevDirectories) => prevDirectories.filter((directory) => directory.id !== id));
			await FileDirectoryService.deleteDirectory(id);
		} catch (e) {
			new ClientError(e).toast();
            setChildDirectoriesInCurrentDirectory(oldChildDirectories);
		}
    }

    const handleButtonClick = () => {
        if (uploadRef?.current)
            uploadRef.current.click();
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
            event.target.value = "";
        }
    };

    const handleFileUpload = async () => {
        uploadFiles([selectedFile as File]);
        setSelectedFile(null);
    }

    // async function downloadFile(name: string, id: string) {
    //     try {
    //         const response = await FileService.downloadFile(id);

    //         // force user to download file (since they clicked on link)
    //         const url = window.URL.createObjectURL(new Blob([response]));
    //         const link = document.createElement("a");
    //         link.download = name;
    //         link.href = url;
    //         document.body.appendChild(link);
    //         link.click();
    //         document.body.removeChild(link);
    //     } catch (e) {
    //         new ClientError(e).toast();
    //     }
    // }

    async function getHashValue(file: File) {
        try {
            const response = await FileService.verifyIfArticleExisting(file);
            return response;
        } catch (e) {
            new ClientError(e).toast();
        }
    }

    const handleMoveDirectoryToParent = async (directory: Directory) => {
        const oldChildDirectories = [...currentDirectory.childDirectories];
        
        try {
            removeDirectoryFromCurrentDirectory(directory.id);

            await FileDirectoryService.updateDirectory(
                directory.id,
                {
                    parentId: currentDirectory.parent?.id || null,
                    name: directory.name,
                }
            );
        } catch (e) {
            new ClientError(e).toast();
            setChildDirectoriesInCurrentDirectory(oldChildDirectories);
        }
    }

    const handleMoveFileToParent = async (fileId: string) => {
        const oldFiles = [...currentDirectory.files];

        try {
            removeFileFromCurrentDirectory(fileId);

            await UserFileDataService.updateDirectory(fileId, currentDirectory.parent?.id || null);
        } catch (e) {
            new ClientError(e).toast();
            setFilesInCurrentDirectory(oldFiles);
        }
    }

    const removeFileFromCurrentDirectory = (id: string) => {
        setFilesInCurrentDirectory((prevFiles) => prevFiles.filter((file) => file.id !== id));
    }

    const removeDirectoryFromCurrentDirectory = (id: number) => {
        setChildDirectoriesInCurrentDirectory((prevDirectories) => prevDirectories.filter((directory) => directory.id !== id));
    }

    return (
        <Box w='full' h='full'>
            <Box p={5}>
                <MiniNav />
            </Box>
            <Box
                w='full'
                h={'calc(100% - 80px)'}
                p='5'
                bgColor={isDragging ? 'gray.300' : 'gray.100'}
                display={'flex'}
                flexDirection={'column'}
            >
                <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Flex flexDir={'row'} alignItems={'flex-end'}>
                        <DirectoryBreadCrumb
                            currentDirectory={currentDirectory}
                            setCurrentDirectoryId={setCurrentDirectoryId}
                        />
                    </Flex>
                    <Flex flexDir={'row'} alignItems={'center'} gap={2}>
                        <Button 
                            mr={4}
                            leftIcon={<SmallAddIcon/>}
                            colorScheme="yellow"
                            onClick={onCreateDirectoryModalOpen}
                        >
                            Folder
                        </Button>
                        {/* <IconButton bg={viewMode == 'grid' ? 'gray.300' : 'none'} size={'md'} onClick={() => { toggleViewMode('grid') }} aria-label='grid view' icon={<MdGridView />} />
                        <IconButton bg={viewMode == 'list' ? 'gray.300' : 'none'} size={'md'} onClick={() => { toggleViewMode('list') }} aria-label='list view' icon={<FaList />} /> */}
                    </Flex>
                </Flex>
                <Flex flexDir={'column'} mt='5' h={'calc(100% - 85px)'}>
                <Box w={'full'} mr='5' onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}>
                        <Card p='4' mb='4' border={'2px dashed #ccc'} h='150px'>
                            <Flex flexDir={'column'} h='100%'>
                                <Box flex={'1 auto'}>
                                    <VStack w={'100%'} h={'100%'}>
                                        <Input
                                            ref={uploadRef}
                                            type="file"
                                            // multiple={false}
                                            style={{ display: "none" }}
                                            onChange={handleFileChange}
                                        />
                                        <Center bgColor={isDragging ? 'gray.100' : 'white'} w={'100%'} h={'100%'} color={'gray.500'} onClick={handleButtonClick} cursor={'pointer'}>
                                            {t('select-a-file')}
                                        </Center>
                                    </VStack>
                                    <Box mt='5'>
                                        {selectedFile && <Text color={'blackAlpha.600'}>{t('file-selected')}: <Text as='b' fontSize='lg' color={'blackAlpha.700'}>{selectedFile.name}</Text></Text>}
                                    </Box>
                                </Box>
                                {/* <Box h={'33px'}>
                                    <Button leftIcon={<MdFileUpload />} border={'1px solid black'} size='sm' w={'100%'} onClick={handleFileUpload}>
                                        {t('upload-label')}
                                    </Button>
                                </Box> */}
                            </Flex>
                        </Card>
                    </Box>
                    <Flex overflow="auto" flexDir={'row'} justifyContent={'center'} w={'full'}>
                        {viewMode == 'grid' &&
                            <Wrap overflow={'auto'} flex={'1 auto'}>
                                {currentDirectory.childDirectories.map((directory) => (
                                    <DraggableBox key={directory.id} id={`directory_${directory.id}`}>
                                        <DroppableArea 
                                            directoryId={directory.id.toString()} 
                                            removeFileFromCurrentDirectory={removeFileFromCurrentDirectory}
                                            removeDirectoryFromCurrentDirectory={removeDirectoryFromCurrentDirectory}
                                        >
                                            <WrapItem flex={'1 auto'} minW='350px' maxW={'350px'}>
                                                <DirectoryCard
                                                    directory={directory}
                                                    onMoveToParent={() => handleMoveDirectoryToParent(directory)}
                                                    onDelete={() => handleDirectoryDelete(directory.id)}
                                                    onOpen={() => {
                                                        setCurrentDirectoryId(directory.id);
                                                    }}
                                                />
                                            </WrapItem>
                                        </DroppableArea>
                                    </DraggableBox>
                                ))}
                                {currentDirectory.files.map((file) => (
                                    <DraggableBox id={`file_${file.id}`} key={file.id}>
                                        <WrapItem key={file.id} flex={'1 auto'} minW='350px' maxW={'350px'}>
                                            <FileCard
                                                file={file}
                                                selectArticle={() => fetchSelectedArticle(file.id)}
                                                onMoveToParent={() => handleMoveFileToParent(file.id)}
                                                onDeleteClick={handleFileDelete}
                                            />
                                        </WrapItem>
                                    </DraggableBox>                                    
                                ))}
                            </Wrap>
                        }
                        {/* {viewMode == 'list' &&
                            <TableContainer w={'100%'} bg={'white'} overflowY={'auto'} maxHeight={'calc(100vh - 230px)'}>
                                <Table variant='simple'>
                                    <Thead>
                                        <Tr>
                                            <Th></Th>
                                            <Th>{t('article-title-label')}</Th>
                                            <Th>{t('created-on-label')}</Th>
                                            <Th>{t('updated-at-label')}</Th>
                                            <Th>{t('action-label')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody h={'100%'} >
                                        {currentDirectory.files.map((file) => (
                                            <Tr>
                                                <Td>

                                                </Td>
                                                <Td>{file.name}</Td>
                                                <Td>{dateToString(new Date(file.createdAt))}</Td>
                                                <Td>{dateToString(new Date(file.updatedAt))}</Td>
                                                <Td p='0 24px'>
                                                    <Flex flexDir={'row'} alignItems={'center'} w={'100%'}>
                                                        <IconButton mr='2' size={'md'} onClick={() => fetchSelectedArticle(file.id)} aria-label='view' icon={<ViewIcon />} />
                                                        <IconButton mr='2' size={'md'} onClick={() => downloadFile(file.name, file.id)} aria-label='download' icon={<DownloadIcon />} />
                                                        <IconButton size={'md'} onClick={() => handleFileDelete(file.id)} aria-label='download' icon={<DeleteIcon />} />
                                                    </Flex>
                                                </Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        } */}
                    </Flex>
                </Flex >

                <FilePreviewModal
                    isOpen={isFilePreviewModalOpen}
                    onClose={onFilePreviewModalClose}
                    html={selectedArticleHtml}
                    isLoading={isLoading}
                />
                <CreateDirectoryModal
                    isOpen={isCreateDirectoryModalOpen}
                    onClose={onCreateDirectoryModalClose}
                    currentDirectoryId={currentDirectoryId}
                    onDirectoryCreate={(dir: Directory) => setChildDirectoriesInCurrentDirectory((prevDirs) => [dir, ...prevDirs])}
                />
            </Box >
            <CuttingFileToast refetchDirectoryContent={fetchDirectoryContents}/>
        </Box>
    
    );
}

export default MyDrive;