import React from "react";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import {
  getCurrentEditLi,
  getSelectNodeIndex,
  isEmptyNode,
  setCursorPosition
} from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { useEditorHook } from "./useEditor.hook.ts";
import { findIndex } from "lodash";

/**
 * 删除时如果删了剩余节点的parent，修改其parentId和level到最近的section
 * @param nodeList
 * @param index
 */
const moveMergeExtraNodes = (nodeList: PaperOutlineNode[], index: number) => {
  const currentNode = nodeList[index]
  if (currentNode.type === OutlineNodeType.Section) {
    const nextLevelIndex = findIndex(nodeList, (node: PaperOutlineNode, i) => {
      return i > index && node.level <= currentNode.level
    })
    const preLevelUpIndex = findIndex(nodeList, (node: PaperOutlineNode, i) => {
      return i < index && node.level <= currentNode.level
    })
    for (let i = index; i <= nextLevelIndex - 1; i++) {
      const node = nodeList[i];
      node.parentId = nodeList[preLevelUpIndex].id; // 更新parentId为preLevelUpIndex的id
      node.level = nodeList[preLevelUpIndex].level + 1; // 更新level为preLevelUpIndex的level + 1
    }
  }
}

/**
 * enter handler
 */
const enterHandler = (
  e: React.KeyboardEvent<HTMLElement>,
  range: Range,
  nodeList: PaperOutlineNode[],
  addNewNode: (node: PaperOutlineNode, type: OutlineNodeType, level: number, content: string) => void
  ) => {
  if (range) {
    const { startContainer, startOffset } = range;
    e.preventDefault();
    const { index, type } = getCurrentEditLi(startContainer, nodeList)
    const currentContent = type === OutlineNodeType.Section
      ? nodeList[index].name
      : nodeList[index].content;
    let newText = ''
    const currentNode = nodeList[index]

    if (startOffset < currentContent?.length) {
      // function
      const updatedContent = currentContent.slice(0, startOffset);
      newText = currentContent.slice(startOffset);
      switch (currentNode.type) {
        case OutlineNodeType.Section: {
          currentNode.name = updatedContent
          break
        }
        case OutlineNodeType.Paragraph: {
          currentNode.content = updatedContent
          break
        }
      }
    }
    const addType = OutlineNodeType.Paragraph
    addNewNode(
      currentNode,
      addType,
      currentNode.type === OutlineNodeType.Section ? (currentNode.level + 1) : currentNode.level,
      newText
    )
  }
}

/**
 * delete handler
 */
export const deleteHandler = (
  e: React.KeyboardEvent<HTMLElement>,
  range: Range,
  selection: Selection,
  nodeList: PaperOutlineNode[],
  setNodeList: (nodeList: PaperOutlineNode[]) => void,
  setOutlineIsChange: (outlineIsChanged: boolean) => void
  ) => {
  if (range) {
    const { startContainer } = range;
    // 单行按多行算，startIndex < n < endIndex, n要直接delete, endIndex后的的内容，补加到startIndex中，endIndex+1后到下一个level+1的section之前的节点，补加到
    // startIndex之前最近的level+1 section
    const selectString = selection.toString();
    if (selectString.length === 0) { // no select text
      const { index } = getCurrentEditLi(startContainer, nodeList)
      if ((startContainer.nodeType === Node.ELEMENT_NODE && isEmptyNode(startContainer as HTMLElement))
        || (startContainer.nodeType === Node.TEXT_NODE && startContainer.textContent.trim() === '')
      ) {

        if (index > 0) {
          e.preventDefault()
          setOutlineIsChange(true)
          const node = nodeList[index - 1]
          let cursorStart = 0
          switch (node.type) {
            case OutlineNodeType.Section: {
              cursorStart = node.name.length
              break
            }
            case OutlineNodeType.Paragraph: {
              cursorStart = node.content.length
              break
            }
          }
          moveMergeExtraNodes(nodeList, index)
          const newNodeList = nodeList.filter((_, i) => i !== index)
          setNodeList([...newNodeList])
          Promise.resolve().then(() => {
            const preDomNode = document.querySelector(`li[data-id="li-${nodeList[index - 1].id}"] .editable-textarea`)
            if (preDomNode) {
              setCursorPosition(preDomNode, cursorStart)
            }
          })
        }
      } else if (range.startOffset === 0) { // 光标到最前面后，尾部还有内容
        e.preventDefault()
        // if p has extra content, current text append to previous p  delete current
        const extraContent = startContainer.nodeType === Node.TEXT_NODE ? startContainer.textContent :
          (startContainer as HTMLElement).innerText
        const preIndex = index - 1
        if (index > 0) { // delete current
          const node = nodeList[preIndex]
          let cursorStart = 0
          switch (node.type) {
            case OutlineNodeType.Section: {
              cursorStart = node.name.length
              node.name += extraContent
              break
            }
            case OutlineNodeType.Paragraph: {
              cursorStart = node.content.length
              node.content += extraContent
              break
            }
          }
          moveMergeExtraNodes(nodeList, index)
          const newNodeList = nodeList.filter((_, i) => i !== index)
          setNodeList([...newNodeList])
          setOutlineIsChange(true)
          Promise.resolve().then(() => {
            const preDomNode = document.querySelector(`li[data-id="li-${nodeList[preIndex].id}"] .editable-textarea`)
            if (preDomNode) {
              setCursorPosition(preDomNode, cursorStart)
            }
          })
        }
      }
      // clear br
      // const currentDom = document.querySelector(`li[data-id="li-${nodeList[index].id}"] .editable-textarea`)
      // if (currentDom?.innerHTML === '<br>') {
      //   currentDom.innerHTML = ''
      // }
    } else {
      const { startContainer, endContainer } = range
      const startIndex = getSelectNodeIndex(nodeList, startContainer.parentElement)
      const endIndex = getSelectNodeIndex(nodeList, endContainer.parentElement)
      const texts = selectString.split(/\n+/)
      const endExtraIndex = texts[texts.length - 1].length
      let extraContent = ''
      const endNode = nodeList[endIndex]
      if (endNode.type === OutlineNodeType.Section) {
        extraContent = endNode.name.slice(endExtraIndex)
      }
      if (endNode.type === OutlineNodeType.Paragraph) {
        extraContent = endNode.content.slice(endExtraIndex)
      }
      if (startIndex !== endIndex) { // 跨多个节点
        e.preventDefault()
        const level = nodeList[endIndex].level;
        // 定义移动前后的索引
        let preLevelUpIndex = startIndex;
        let nextLevelUpIndex = endIndex === nodeList.length - 1 ? endIndex : (endIndex + 1); // endIndex的下一个level比当前节点高的节点
        let cursorStart = 0
        for (let i = endIndex; i >= 0; i--) {
          const node = nodeList[i];
          // 找到endIndex之后的第一个层级更高的节点
          if (i > endIndex && node.level < level) {
            nextLevelUpIndex = i;
          }
          // 修改startIndex节点的内容

          if (i === startIndex) {
            if (node.type === OutlineNodeType.Section) {
              const startExtraContent = node.name.replace(texts[0], '')
              cursorStart = startExtraContent.length
              node.name = startExtraContent + extraContent
            } else if (node.type === OutlineNodeType.Paragraph) {
              const startExtraContent = node.content.replace(texts[0], '')
              cursorStart = startExtraContent.length
              node.content = startExtraContent + extraContent
            }
          }

          // 删除startIndex到endIndex之间的节点
          if (i > startIndex && i <= endIndex) {
            nodeList.splice(i, 1);
          }
          // 找到startIndex之前的最后一个层级更高的节点
          if (i < startIndex && node.level < level
            && node.type === OutlineNodeType.Section) {
            preLevelUpIndex = i;
            break // 提前结束循环，减少不必要的遍历
          }
        }
        nextLevelUpIndex = nextLevelUpIndex - endIndex + startIndex

        if (preLevelUpIndex > -1) {
          // 修改endIndex到nextLevelUpIndex之间节点的parentId和level
          for (let i = endIndex - startIndex + 1; i <= nextLevelUpIndex; i++) {
            const node = nodeList[i];
            node.parentId = nodeList[preLevelUpIndex].id; // 更新parentId为preLevelUpIndex的id
            node.level = nodeList[preLevelUpIndex].level + 1; // 更新level为preLevelUpIndex的level + 1
          }
        }
        setNodeList([...nodeList])
        setOutlineIsChange(true)
        Promise.resolve().then(() => {
          const preDomNode = document.querySelector(`li[data-id="li-${nodeList[startIndex].id}"] .editable-textarea`)
          if (preDomNode) {
            setCursorPosition(preDomNode, cursorStart)
          }
        })
      }
    }
  }
}

/**
 * watch input keyboard key
 */
export const useEditorKeyboard = () => {
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()
  const { addNewNode } = useEditorHook()
  const { syncDOMToNodes } = useEditorHook()

  const keyboardHandler = async (
    e: React.KeyboardEvent<HTMLElement>,
  ) => {
    // window.scrollBy({
    //   top: 200 - bottomOffset,
    //   behavior: "smooth"
    // });
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      switch (e.key) {
        // add new node in next cursor in nodeList
        case 'Enter': {
          syncDOMToNodes(nodeList)
          enterHandler(e, range, nodeList, addNewNode)
          break
        }
        case 'Backspace':
        case 'Delete': {
          deleteHandler(e, range, selection, nodeList, setNodeList, setOutlineIsChange)
          break
        }
      }
    }
  }

  return {
    keyboardHandler
  }
}