import {
  Text,
} from "@chakra-ui/react";
// TODO: consider xss attack
const EditorContent: React.FC<{
  content: string;
  dataId: string
  type?: 'default' | 'table' | 'equation' | 'image'
}> = (props) => {
  return (
    <>
      <Text
        display={'inline-block'}
        className={'editable-textarea'}
        data-id={props.dataId}
        fontFamily='"Times New Roman", Times, serif'
        sx={{
          width: 'calc(100% - 2rem)',
          minHeight: "24px",
        }}
        dangerouslySetInnerHTML={{ __html: props.content }}
      >
      </Text>
    </>
  );
};
export default EditorContent;
