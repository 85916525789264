import {
  Flex,
  Box,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
} from "@chakra-ui/react";
import PaperEditorConversationPanel from "../components/PaperEditorConversationPanel.tsx/PaperEditorConversationPanel";
import { OutlineNode } from "../types/Writer/OutlineNode";
import CrossReferencePanel from "../components/PaperEditorComponents/CrossReferencePanel";
import CitationPanel from "../components/PaperEditorComponents/CitationPanel";
import { MinusIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { Function1 } from "lodash";
import { useRecoilValue } from "recoil";
import { selectedOutlineNodeId } from "../atoms/rootAtom";
import { pxToRem } from "../utils/editor.ts";
import useOutlineStore from "../stores/modules/outline.ts";

type EditorModelRightProps = {
  rightBarWidth: string
  onAddLabel: Function1<OutlineNode[], void>;
}

const EditorModelRight: React.FC<EditorModelRightProps> = ({ rightBarWidth }) => {
  const [isLibraryOpen, setIsLibraryOpen] = useState<boolean>(true);
  const selectedNodeId = useRecoilValue(selectedOutlineNodeId);
  const { outline } = useOutlineStore()

  const togglePanelCollapse = () => {
    setIsLibraryOpen(!isLibraryOpen);
  };

  return (
    <Flex
      flexDir={"column"}
      w={rightBarWidth}
      h={"100%"}
      position={"relative"}
      overflow={"hidden"}
      p={5}
      transition={"all 0.5s ease"}
    >
      <Card
        mt={0}
        mb={4}
        bg={"#ffffff"}
        border={"1px solid #ccc"}
        borderRadius={"25px"}
        boxShadow={'none'}
        h={isLibraryOpen ? pxToRem(320) : pxToRem(60)}
        transition={"all 0.5s ease"}
      >
        <CardHeader px={4} pt={3} pb={2}>
          <Flex flexDir={"row"} justifyContent={"space-between"}>
            <Flex flexDir={"row"} alignItems={"center"}>
              <Image
                w={pxToRem(20)}
                src="/static/editor/note-2.svg"
                alt="Doenba"
                mr={2}
              />
              <Heading size="md">Library</Heading>
            </Flex>
            <Box>
              <IconButton
                onClick={togglePanelCollapse}
                aria-label="library-collapse"
                color={"#8391BC"}
                icon={<MinusIcon />}
                size={"sm"}
                variant={"ghost"}
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody overflow={"hidden"} maxH={"35vh"} px={'1rem'} py={0}>
          <Tabs variant="soft-rounded" bg={"brand"} size={"xs"} mt={2}>
            <TabList border={"1px solid #ccc"} borderRadius={pxToRem(12)} p={"2px"}>
              <Tab
                fontSize={"1rem"}
                w="50%"
                color={"#333"}
                borderRadius={pxToRem(10)}
                _selected={{
                  bg: '#B1D6FF'
                }}
              >
                Cross Reference
              </Tab>
              <Tab
                fontSize={"1rem"}
                w="50%"
                color={"#333"}
                _selected={{
                  bg: '#B1D6FF'
                }}
                borderRadius={pxToRem(10)}
              >
                Citation
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel py={'0.5rem'} px={0}>
                <CrossReferencePanel outline={outline}/>
              </TabPanel>
              <TabPanel py={1} px={0}>
                <CitationPanel />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
      <Card
        bg={"#ffffff"}
        border={"1px solid #ccc"}
        borderRadius={"25px"}
        boxShadow={'none'}
        h={isLibraryOpen ? "calc(100% - 20.625rem)" : "calc(100% - 4.375rem)"}
        transition={"all 0.5s ease"}
      >
        <CardBody overflow={'hidden'}>
          <PaperEditorConversationPanel
            selectedNodeId={selectedNodeId}
            setSelectedNodeId={undefined}
            outlineNodes={undefined}
          />
        </CardBody>
      </Card>
    </Flex>
  );
};
export default EditorModelRight;