import { PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { getSelectNodeIndex } from "../../utils/editor.ts";
import { SelectContents } from "../../types/Writer/SelectContents.ts";
/**
 * editor mouse event handler
 */

export const useEditorMouseEvent = () => {
  // select multi content
  const handlerSelection = (nodeList: PaperOutlineNode[]) => {
    const selection = window.getSelection();
    const selectString = selection.toString();
    const selectContents: SelectContents = []
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
      const { startContainer, endContainer } = range
      const startIndex = getSelectNodeIndex(nodeList, startContainer.parentElement)
      if (selectString.length > 0 && startIndex !== -1) {
        const endIndex = getSelectNodeIndex(nodeList, endContainer.parentElement)

        const texts = selectString.split(/\n+/)
        for (let i = startIndex; i <= endIndex; i++) {
          selectContents.push({
            id: nodeList[i].id,
            select_content: texts[i - startIndex]
          })
        }
      }
    }

    return{
      selectString,
      selectContents
    }
  }

  return {
    handlerSelection
  }
}