import { Flex, Button, Heading, SlideFade, IconButton } from "@chakra-ui/react";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
	currentOpenedArticleIdState,
	popUpShowStatus,
	selectedArticlesState,
	uploadingFile as uploadingFileFromRecoil,
} from "../../atoms/rootAtom";
import { useEffect } from "react";
import useUnprocessedFileHandler from "../../hooks/useUnprocessedFileHandler";
import { CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";

function FileIsReadyTopPopUp() {
	// const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);
	const setSelectedArticles = useSetRecoilState(selectedArticlesState);
	const setCurrentOpenArticleId = useSetRecoilState(currentOpenedArticleIdState);
	// const [currentOpenArticleId, setCurrentOpenArticleId] = useRecoilState(currentOpenedArticleIdState);
	const [popUpShown, setPopUpShown] = useRecoilState(popUpShowStatus);
	const [uploadedFile, setUploadedFile] = useRecoilState(uploadingFileFromRecoil);
	const { hasFileProcessedSuccessfully } = useUnprocessedFileHandler();
    const { t } = useTranslation();

	const hasUploadedFileUploadedSuccessfully =
		uploadedFile && hasFileProcessedSuccessfully(uploadedFile);

	useEffect(() => {
		if (hasUploadedFileUploadedSuccessfully) {
			setPopUpShown(true);
		}
	}, [hasUploadedFileUploadedSuccessfully]);

	const readArticle = () => {
		setSelectedArticles([uploadedFile]);
		setCurrentOpenArticleId(uploadedFile.id);
		closePopUp();
	};

	const closePopUp = () => {
		setPopUpShown(false);
		setUploadedFile(null);
	};

	return (
		<>
			{popUpShown && (
				<SlideFade in offsetY="-100%" offsetX="0%">
					<Flex
						border={"1px dashed black"}
						borderRadius={"6px"}
						pl={3}
						pr={1}
						py={2}
						bg={"yellow.400"}
						position={"absolute"}
						zIndex={100}
						top={0}
						left={0}
						w={"full"}
						flexDir={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
					>
						<Flex
							maxW={"70%"}
							flexDir={"row"}
							alignItems={"center"}
							justifyContent={"flex-start"}
						>
							<Heading
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								size={"sx"}
								mr={2}
								className="text-ellipsis"
							>
								{uploadedFile.name}
							</Heading>
						</Flex>
						<Flex flexDir="row" justifyContent="space-between" alignItems={"center"}>
							<Button
								size={"xs"}
								mr={2}
								border={"1px solid black"}
								onClick={readArticle}
							>{t('open')}
							</Button>
							<IconButton
								size={"xs"}
								aria-label="Search database"
								icon={<CloseIcon />}
								onClick={closePopUp}
							/>
						</Flex>
					</Flex>
				</SlideFade>
			)}
		</>
	);
}

export default FileIsReadyTopPopUp;
