import { create } from 'zustand';
import { AIDataType } from "../../types/Writer/AIData.ts";

interface CounterState {
  aiType: AIDataType | null;
  updateAiType: (newOutline: AIDataType | null) => void;
}

const useAiTypeStore = create<CounterState>((set) => ({
  aiType: null,
  updateAiType: (newAiType) => set(() => ({ aiType: newAiType })),
}))

export default useAiTypeStore;

