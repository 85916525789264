import React from "react";
import { Flex, IconButton, Image, Text, } from "@chakra-ui/react";
import { pxToRem } from "../../../utils/editor.ts";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { RephraseResult } from "../../../types/Writer/RephraseSentencesResult.ts";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { OutlineNodeType } from "../../../types/Writer/OutlineNode.ts";
import { cloneDeep, find } from "lodash";

interface RephraseProps {
  result: RephraseResult
}

export const Rephrase: React.FC<RephraseProps> = (
  {
    result
  }
) => {
  const { nodeList, setNodeList, setOutlineIsChange } = useOutlineStore()
  return (<>
      <Text
        width={pxToRem(248)}
        aria-multiline={true}
        fontSize={14}
        fontWeight={400}
      >
        {result.select_content_rephrased_result}
      </Text>
      <Flex width={pxToRem(248)}
            alignItems={"center"}
            justifyContent={"start"}>
        <IconButton
          mr={"10px"}
          size={"sm"}
          minWidth={pxToRem(16)}
          width={pxToRem(16)}
          bg="transparent"
          p={0}
          _hover={{ bg: 'transparent' }}
          icon={
            <Image
              w={pxToRem(16)}
              objectFit="cover"
              src={"/static/ToolAI/useContent.png"}
            />
          }
          aria-label="replace"
          onClick={(e) => {
            e.stopPropagation()
            if (!result.replaced) {
              const newNodeList = cloneDeep(nodeList)
              const node = find(newNodeList, { id: result.id })
              switch (node.type) {
                case OutlineNodeType.Section: {
                  node.name = node.name.replace(result.select_content, result.select_content_rephrased_result)
                  break
                }
                case OutlineNodeType.Paragraph: {
                  // node.content = node.content.replace(result.select_content,
                  //         `<span class="replacedEffect">${result.select_content_rephrased_result}</span>`)
                  node.content = node.content.replace(result.select_content, result.select_content_rephrased_result)
                  break
                }
              }
              result.replaced = true
              setOutlineIsChange(true)
              setNodeList(newNodeList) // todo: 这里修改的nodeList，导致updateOutline中比较实际文本那里没有设置成功outlineIsChange为true
              window.getSelection()?.removeAllRanges()
            }
          }}
        />
        {result.replaced && <CheckCircleIcon
            sx={{
              color: 'hsla(128, 71%, 50%, 0.8)'
            }}/>}
      </Flex>
    </>
  )
}