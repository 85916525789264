import { Box, Center, Flex, Image, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/**
 * empty section component
 * @constructor
 */
export const EmptySection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box bgColor={"#FBFCFF"} w={"100%"} h={`100%`}>
      <Center h={"100%"} w={"100%"}>
        <Flex
          direction="column"
          bgColor={"white"}
          borderRadius={"10px"}
          h={"304px"}
          w={"512px"}
          border={'1px solid #D3D7E2'}
          p={37}
        >
          <Text color={"#8E9ABC"} fontSize={"11px"}>
            {t("editor-editormodel-noSectionTip1")}
          </Text>
          <Spacer/>
          <Flex>
            <Spacer/>
            <Text color={"#333333"} fontSize={"11px"} mt={3}>
              {t("editor-editormodel-noSectionTip2.1")}
            </Text>
            <Image
              w={"40px"}
              objectFit="cover"
              src="/static/editor/add-square.png"
            />
            <Text color={"#333333"} fontSize={"11px"} mt={3}>
              {t("editor-editormodel-noSectionTip2.2")}
            </Text>
            <Spacer/>
          </Flex>
          <Spacer/>

          <Box
            marginLeft={"100px"}
            w={"50%"}
            h={"50px"}
            onClick={() => navigate("/editor")}
            cursor={"pointer"}
          >
            <Image
              objectFit="cover"
              src={"/static/doenba-logo-blue.png"}
            />
          </Box>
          <Spacer/>
        </Flex>
      </Center>
    </Box>
  )
}