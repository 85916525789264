import { Button, Box, Flex, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import About from '../../pages/About';
import CaseStudyPage from '../../pages/CaseStudy';
import VideoCardsWrapper from '../VideoCarousel/VideoCardsWrapper';
import FeatureCards from '../FeatureWrapper/FeatureCards';

const CompanyInfoTabs = () => {
    const { t } = useTranslation();
    const [targetPage, setTargetPage] = useState<string>('about');

    const menuItems = [
		{ name: 'mini-nav-about-label', value: 'about' },
		{ name: 'in-action-label', value: 'case-study' },
        { name: 'demo-videos', value: 'demos' },
		{ name: 'features-label', value: 'features' }
	];

    return (
        <Flex flexDir={'row'} mt={8} w={'full'} h={'calc(100% - 60px)'} overflow={'hidden'}>
            <VStack spacing={4} align='stretch' pt={5}>
                {menuItems.map((item) => (
                    <Button
                        size={'lg'}
                        variant={'link'}
                        key={item.value}
                        textAlign={'left'}
                        display={'flex'}
                        justifyContent={'flex-start'}
                        pr={8}
                        pb={2}
                        _hover={{ 'textDecor' : 'none' }}
                        onClick={() => setTargetPage(item.value)}
                        color={ targetPage === item.value ? 'black' : '#929292'}
                        borderRadius={0}
                        borderBottom={ targetPage === item.value ? '1px solid #929292' : '1px solid transparent'}
                    >
                        {t(item.name)}
                    </Button>
                ))}
            </VStack>
            <Box overflow={'auto'} px={6}>
                { targetPage === 'about' && <About />}
                { targetPage === 'case-study' && <CaseStudyPage />}
                { targetPage === 'demos' && <VideoCardsWrapper />}
                { targetPage === 'features' && <FeatureCards />}
            </Box>
        </Flex>
    );
}

export default CompanyInfoTabs;