import React, { useState } from "react";
import { AIDataType } from "../../../types/Writer/AIData.ts";
import useAiTypeStore from "../../../stores/modules/aiType.ts";
import { Suggestion } from "./Suggestion.tsx";
import { AiPanelContainer } from "./AiPanelContainer.tsx";

interface AIPanelProps {
  updateOutline: (updated: boolean) => void
}

export const AIPanel: React.FC<AIPanelProps> = (
  {
    updateOutline
  }
) => {
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const { aiType } = useAiTypeStore()
  /**
   * rest value after switch aiType
   * @param value
   */
  // const resetAIprogress = (value: AIDataType) => {
  //   if (value) {
  //     setIsCalculated(false);
  //     setAIData([]); // 可能想回显之前的数据
  //   }
  //   updateAiType(value);
  // }
 // todo: 需要一个map存储不同sectionNode的三种ai使用状态(loading, result), 方便切换时回显
  // const saveAIData = (id: number, aiType: number, aiData: SectionData) => {
  //   newAIDatas[id][aiType] = aiData;
  // };

  switch (aiType) {
    case AIDataType.rephrase:
      return (
        <AiPanelContainer type={'rephrase'} setIsCalculated={setIsCalculated} isCalculated={isCalculated} />
      );
    case AIDataType.suggestion:
      return (
        <Suggestion setIsCalculated={setIsCalculated} isCalculated={isCalculated}  />
      );
    case AIDataType.reorganize:
      return (
        <AiPanelContainer type={'reorganize'} setIsCalculated={setIsCalculated} isCalculated={isCalculated} updateOutline={updateOutline} />
      );
    default:
      return <p style={{ color: "gray" }}>Unknown status</p>;
  }
}