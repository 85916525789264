import { DownloadIcon } from "@chakra-ui/icons";
import { Button, Text, Select, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Input, IconButton, InputGroup, InputRightAddon, Icon } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FileDetails } from "../types/FileDetails";
import { ClientError } from "../utils/clientError";
import { ArticleProcessingService } from "../services/articleProcessingService";
import { BsTextParagraph } from "react-icons/bs";
import { IoLanguage } from "react-icons/io5";
import { MdOutlineSchool } from "react-icons/md";
import { GrNotes } from "react-icons/gr";
import { getSummaryToUse } from "../services/summaryService";
import { useTranslation } from "react-i18next";
import LatexSupportedText from "./LatexSupportedText";
// import { LiaReact } from "react-icons/lia";

interface Props {
	file: FileDetails;
	handleClose: () => void;
	isOpen: boolean;
}

const SummaryModal = ({ file, handleClose, isOpen }: Props) => {
	const [numberOfWords, setNumberOfWords] = useState<number>(150);
	const [summary, setSummary] = useState<string>(file?.dooyeedSummary ?? file?.summary ?? "");
	const [outputFileName, setOutputFileName] = useState<string>('');
	const [topic] = useState('');
	const [textLevel, setTextLevel] = useState('College');
	const [outputLang, setOutputLang] = useState('');
	const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);
	const { t } = useTranslation();


	useEffect(() => {
		setSummary(getSummaryToUse(file));
	}, [file]);

	async function handleRenewClick() {
		try {
			setIsGeneratingSummary(true);
			const summary = await ArticleProcessingService.getSummary({
				fileId: file.id,
				summaryLength: numberOfWords,
				summaryLanguage: outputLang,
				summaryLevel: textLevel,
				summaryTopic: topic
			});

			setSummary(summary);
		} catch (e) {
			new ClientError(e).toast();
		} finally {
			setIsGeneratingSummary(false);
		}
	}

	async function handleDownloadClick() {
		const element = document.createElement("a");
		const file = new Blob([summary], { type: 'text/plain' });
		element.href = URL.createObjectURL(file);
		element.download = outputFileName ? outputFileName + '.txt' : "Summary.txt";
		document.body.appendChild(element); // Required for this to work in Firefox
		element.click();
	}

	if (!file) {
		return null;
	}

	return (
		<Modal isOpen={isOpen} onClose={handleClose} scrollBehavior="inside" size={'2xl'}>
			<ModalOverlay />
			<ModalContent >
				<ModalHeader>
					{t('custom-summary-label')}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					{isGeneratingSummary && 'Generating summary now.'}
					{!isGeneratingSummary &&
						<LatexSupportedText>
							{summary}
						</LatexSupportedText>
					}
				</ModalBody>

				<ModalFooter borderTop={'1px solid #e9e9e9'} mt={5} display={'flex'} flexDir={'row'} justifyContent={'flex-start'}>
					<Flex flexDir={'column'} w={'full'}>
						<Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} w={'full'}>
							<Flex flexDir='row' alignItems='center' mr={2} w={'45%'}>
								<Icon as={BsTextParagraph} mr={2}/>
								<NumberInput w={'full'} value={numberOfWords} min={0} max={1000} step={50} onChange={value => setNumberOfWords(parseInt(value))}>
									<NumberInputField />
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<Text ml={2} fontSize={'sm'} fontWeight={700}>{t('words')}</Text>
							</Flex>
							<Flex flexDir='row' alignItems='center' mr={2} w={'45%'}>
								<Icon as={IoLanguage} mr={2}/>
								<Input placeholder={t('summary-lang-label')} w={'full'} value={outputLang} onChange={(e) => setOutputLang(e.target.value)} />
							</Flex>
							
						</Flex>
						<Flex flexDir={'row'} justifyContent={'space-between'}  alignItems={'center'} w={'full'} mt={4}>
							<Flex flexDir='row' alignItems='center' mr={2} w={'45%'}>
								<Icon as={MdOutlineSchool} mr={2}/>
								<Select mb={2} w={'full'} value={textLevel} onChange={(e) => setTextLevel(e.target.value)}>
									<option value='Middle School'>{t('middle-school')}</option>
									<option value='High School'>{t('high-school')}</option>
									<option value='College'>{t('college')}</option>
									<option value='Expert'>{t('expert')}</option>
									<option value='Others'>{t('others')}</option>
								</Select>
								<Text ml={2} fontSize={'sm'} fontWeight={700}>{t('level')}</Text>
							</Flex>
							<Flex flexDir='row' alignItems='center' mr={2} w={'45%'}>
								<Icon as={GrNotes} mr={2}/>
								<Flex flexDir={'row'} alignItems={'center'}>
									<InputGroup size='md'>
										<Input placeholder={t('custom-summary-label')} w={'full'} value={outputFileName} onChange={(e) => setOutputFileName(e.target.value)} />
										<InputRightAddon>
											.txt
										</InputRightAddon>
									</InputGroup>
									<IconButton
										ml='2'
										size={'md'}
										aria-label='help center'
										icon={<DownloadIcon />}
										onClick={handleDownloadClick}
									/>
								</Flex>
							</Flex>
						</Flex>
						{/* <Flex flexDir='row' alignItems='center' mr={2} w={'full'} mt={2}>
							<Icon as={LiaReact} mr={2}/>
							<Input value={topic} onChange={(e) => setTopic(e.target.value)} placeholder='Enter topic (or leave blank to summarize all)' w={'full'} />
						</Flex> */}
						
						<Flex w={'full'} mt={5}>
							<Button
								w={'full'}
								isLoading={isGeneratingSummary}
								onClick={handleRenewClick}
							>
								{t('generate-summary')}
							</Button>
						</Flex>
					</Flex>

				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default SummaryModal;
