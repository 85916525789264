import { Button, Center, Flex, HStack, IconButton, Image } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AIDataType } from "../../types/Writer/AIData";
import { findNodeByIds, pxToRem } from "../../utils/editor.ts";
import useAiTypeStore from "../../stores/modules/aiType.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { cloneDeep } from "lodash";

const ToolAIBox: React.FC = () => {
  const { t } = useTranslation();
  const { aiType, updateAiType } = useAiTypeStore()
  const { outline, currentSectionIndex } = useOutlineStore()
  const AIBtns = [
    { img: '/static/ToolAI/magicpen.png', label: t("editor-editormodel-AI-Rephrase"), type:AIDataType.rephrase },
    { img: '/static/ToolAI/clipboard-tick.png', label: t("editor-editormodel-AI-Suggestions") , type:AIDataType.suggestion },
    { img: '/static/ToolAI/layer.png', label: t("editor-editormodel-AI-Reorganize") , type:AIDataType.reorganize },
  ]

  const onClickTool = (btn: string) => {
    switch (btn) {
      case "Bold": { // 跨行加粗/倾斜
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);

        if (!range || range.collapsed) return;

        const selectedText = range.toString();
        const newText = `**${selectedText}**`;
        const { startContainer } = range
        console.log(startContainer)
        const newOutline = cloneDeep(outline)
        const node = findNodeByIds([newOutline.nodes[currentSectionIndex]], [1])[0]
        node.content = node.content.slice(0, range.startOffset) + newText + node.content.slice(range.endOffset)
        // setOutline({
        //   nextId: outline.nextId,
        //   nodes: newOutline.nodes
        // })
      }
    }
  };

  return (
    <Center>
      <HStack
        bg={'#FFFFFF'}
        border={"1px solid #ccc"}
        width={pxToRem(770)}
        height={pxToRem(48)}
        borderRadius={"10px"}
        gap={0}
      >
        <Flex
          borderRight={"1px solid #D3D7E2"}
          width={pxToRem(271)}
          height={pxToRem(48)}
          pl={pxToRem(15)}
          gap={pxToRem(16)}
          justifyContent={'start'}
          alignItems={'center'}
        >
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="cover"
                src="/static/ToolAI/b.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("Bold")}
          />
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="cover"
                src="/static/ToolAI/u.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("U")}
          />
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="cover"
                src="/static/ToolAI/i.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("I")}
          />
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="cover"
                src="/static/ToolAI/equation.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("I")}
          />
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="contain"
                src="/static/ToolAI/table.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("I")}
          />
          <IconButton
            disabled={!onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={pxToRem(26)}
                h={pxToRem(26)}
                objectFit="cover"
                src="/static/ToolAI/image.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => onClickTool("I")}
          />
        </Flex>
        <Flex width={pxToRem(499)} flex={1} alignItems={'center'} justifyContent={'start'} pl={pxToRem(20)} gap={pxToRem(16)}>
          {AIBtns.map((btn) => (
            <Button
              key={btn.label}
              width={btn.type === AIDataType.rephrase ? pxToRem(131) :
                (btn.type === AIDataType.suggestion ? pxToRem(155) : pxToRem(145))}
              height={pxToRem(36)}
              value={btn.label}
              bgColor={btn.type === aiType ? "#C9D9E8":"transparent" }
              fontWeight="bold"
              textColor={"#333333"}
              _hover={{  bgcolor:"transparent" }}
              onClick={()=>{
                if (btn.type === aiType) {
                  updateAiType(null)
                } else {
                  updateAiType(btn.type)
                }
              }}
              leftIcon={
                <Image
                  w={pxToRem(24)}
                  objectFit="cover"
                  src={btn.img}
                  alt="Doenba"
                />
              }
            >
              {btn.label}
            </Button>
          ))}
        </Flex>
      </HStack>
    </Center>
  );
};
export default ToolAIBox;
