import { create } from 'zustand';
import { PaperOutline, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";

interface CounterState {
  outline: PaperOutline | null
  nodeList: PaperOutlineNode[]
  nextId: number
  currentSectionIndex: number
  outlineIsChange: boolean
  toolPopOpen: boolean
  setNextId: (newId: number) => void
  setOutline: (newOutline: PaperOutline | null) => void
  setNodeList: (newNodeList: PaperOutlineNode[]) => void
  setOutlineIsChange: (change: boolean) => void
  setToolPopOpen: (open: boolean) => void
  setCurrentSectionIndex: (newIndex: number) => void
}

const useOutlineStore = create<CounterState>((set) => ({
  outline: null,
  nodeList: [],
  nextId: 0,
  toolPopOpen: false,
  currentSectionIndex: 0,
  outlineIsChange: false,
  setNextId: (newId) => set(() => ({ nextId: newId })),
  setOutline: (newOutline) => set(() => ({ outline: newOutline })),
  setNodeList: (newNodeList) => set(() => ({ nodeList: newNodeList })),
  setOutlineIsChange: (change) => set(() => ({ outlineIsChange: change })),
  setToolPopOpen: (open) => set(() => ({ toolPopOpen: open })),
  setCurrentSectionIndex: (newIndex) => set(() => ({ currentSectionIndex: newIndex }))
}));

export default useOutlineStore;

