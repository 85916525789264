import React, { useState } from "react";
import {
  Box,
  Button, Flex,
  HStack,
  IconButton,
  Image,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack
} from "@chakra-ui/react";
import { OutlineNodeType, PaperOutlineNode } from "../../types/Writer/OutlineNode.ts";
import { LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6, LuText } from "react-icons/lu";
import { PiDotsSixVerticalBold } from "react-icons/pi";
import { pxToRem } from "../../utils/editor.ts";
import EditorContent from "./EditorContent.tsx";
import { useEditorKeyboard } from "../../hooks/editor/useEditorKeyboard.hook.ts";
import { useTranslation } from "react-i18next";
import { useEditorMouseEvent } from "../../hooks/editor/useEditorMouseEvent.ts";
import eventBus, { EventType } from "../../hooks/useEventBus.ts";
import { SelectContent } from "../../types/Writer/SelectContents.ts";
import useAiTypeStore from "../../stores/modules/aiType.ts";
import { AIDataType } from "../../types/Writer/AIData.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { useEditorHook } from "../../hooks/editor/useEditor.hook.ts";

/**
 * main editor area
 */
interface SectionContentProps {
  listRef: React.MutableRefObject<HTMLUListElement | null>
  updateOutline: (updated: boolean) => void
}

export const SectionContent: React.FC<SectionContentProps> = (
  {
    listRef,
    updateOutline
  }
) => {
  const { t } = useTranslation();
  const { keyboardHandler } = useEditorKeyboard()
  const { handlerSelection } = useEditorMouseEvent()
  const [mousePos, setMousePos] = useState([0, 0]);
  const [selectContentArr, setSelectContentArr] = useState<SelectContent[]>([]);
  const { updateAiType } = useAiTypeStore()
  const { nodeList, toolPopOpen, setToolPopOpen } = useOutlineStore();
  const { addNewNode } = useEditorHook();

  /**
   * click add, display selections
   * @param node
   * @param btnClick
   */
  const getSectionButton = (
    node: PaperOutlineNode,
    btnClick: (level: number) => void,
  ) => {
    const level = node.level + 1
    if (level > 6) return undefined;
    const levelIcons = {
      2: <LuHeading2/>,
      3: <LuHeading3/>,
      4: <LuHeading4/>,
      5: <LuHeading5/>,
      6: <LuHeading6/>
    }
    const menuItems = []
    const endLevel = node.type === OutlineNodeType.Section ? (level + 1) : level
    for (let i = 2; i < endLevel; i++) {
      const icon = levelIcons[i]
      menuItems.push(<MenuItem
        onClick={() => btnClick(i)}
        _hover={{ bgColor: "#3898FF61" }}
        borderRadius={10}
        key={i}
      >
        {icon}
        <Text pl={"3px"} fontSize={14}>
          {t("editor-editormodel-midNewSubsection" + i)}
        </Text>
      </MenuItem>)
    }
    return menuItems
  }

  /**
   * watch input keyboard key
   */
  const useKeyboard = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key.toLowerCase() === 'v' && (e.ctrlKey || e.metaKey)) { // paste
      e.preventDefault()
      const clipboardText = await navigator.clipboard.readText()
      document.execCommand('insertText', false, clipboardText)
    } else if ((e.ctrlKey || e.metaKey) && e.key === "s") {
      e.preventDefault()
      updateOutline(true)
    } else {
      await keyboardHandler(e)
      if (toolPopOpen) {
        setToolPopOpen(false) // 影响键盘输入
      }
    }
  };

  return (
    <Box overflowY={'auto'} height={`100%`}>
      <List
        contentEditable
        suppressContentEditableWarning={true}
        ref={listRef}
        flex={1}
        borderRadius={"10px"}
        pr="1rem"
        onKeyDown={useKeyboard}
        onMouseDown={(e) => {
          const target = e.target as HTMLElement;
          if (!target.classList.contains("editable-textarea")) {
            e.preventDefault(); // prevent cursor placement
          }
          if (e.button !== 2) {
            setToolPopOpen(false)
          }
        }}
        onMouseUp={(e) => {
          const { selectString, selectContents } = handlerSelection(nodeList);
          // todo 点击选中文本中间，selectString不为空
          if (selectString) { // select event
            if (!toolPopOpen) {
              setToolPopOpen(true)
            }
            setMousePos([e.clientX + 20, e.clientY + 30]);
            setSelectContentArr(selectContents)
          }
        }}
        sx={{
          border: "none",
          outline: "none",
          boxShadow: "none",
        }}
        _focus={{
          border: "none",
          boxShadow: "none",
        }}
      >
        {nodeList?.map((child, index) =>
          child == undefined ? undefined : (
            <ListItem
              key={child.id.toString() + child.name + index}
              data-id={`li-${child.id}`}
              data-type={child.type}
            >
              <Flex
                alignItems={"flex-start"}
                pl={child.level === 1 ? 0 : pxToRem(28 * (child.level - 1))}
              >
                <HStack contentEditable={false} userSelect={"none"}>
                  <Menu matchWidth={true}>
                    <MenuButton
                      as={IconButton}
                      mt={8}
                      size={"xs"}
                      borderRadius={'50%'}
                      bgColor={"transparent"}
                      _hover={{ bgColor: "transparent" }}
                      _focusVisible={{ boxShadow: 'none' }}
                      icon={
                        <Image
                          w={"1rem"}
                          userSelect={"none"}
                          objectFit="cover"
                          src="/static/editor/add.png"
                        />
                      }
                    />
                    <MenuList
                      border={"1px solid #cccccc"}
                      minWidth={109}
                    >
                      <MenuItem
                        _hover={{ bgColor: "#3898FF61" }}
                        borderRadius={10}
                        key={OutlineNodeType.Paragraph}
                        onClick={() => {
                          addNewNode(
                            child,
                            OutlineNodeType.Paragraph,
                            child.type === OutlineNodeType.Section ? (child.level + 1) : child.level
                          )
                        }}
                      >
                        <LuText/>
                        <Text pl={"3px"} fontSize={14}>
                          {t("editor-editormodel-midNewParagraph")}
                        </Text>
                      </MenuItem>
                      {getSectionButton(child, (level: number) => {
                        addNewNode(
                          child,
                          OutlineNodeType.Section,
                          level
                        )
                      })}
                    </MenuList>
                  </Menu>
                </HStack>
                <HStack
                  mt={1}
                  pt={7}
                  alignItems={"flex-start"}
                  width={'calc(100% - 30px)'}
                  data-id={child.id.toString() + '-' + child.level.toString()}
                >
                  {child.level != 1 && (
                    <IconButton
                      size={"xs"}
                      color={"#8E9ABC"}
                      bgColor={"transparent"}
                      cursor={'grab'}
                      _hover={{ borderRadius: "15px" }}
                      icon={
                        <PiDotsSixVerticalBold
                          style={{ width: pxToRem(20), height: pxToRem(20) }}
                        />
                      }
                      aria-label={""}
                    />
                  )}
                  {child.type === "paragraph" ? (
                    <EditorContent
                      content={child.content}
                      dataId={child.id.toString() + '-' + child.level.toString()}
                    />
                  ) : (
                    <Text
                      data-id={child.id.toString() + '-' + child.level.toString()}
                      className={'editable-textarea section-edit-type'}
                      bgColor={"transparent"}
                      _hover={{ bgColor: "transparent" }}
                      textColor={
                        child.level == 1 ? "#1D8AFF" : "#8E9ABC"
                      }
                      data-placeholder={child.placeholder}
                      flex={1}
                      minHeight={pxToRem(24)}
                      fontSize={"1rem"}
                      fontWeight={"bold"}
                      position={'relative'}
                    >
                      {child.name}
                    </Text>
                  )}
                </HStack>
              </Flex>
            </ListItem>
          )
        )}
      </List>
      {/** cache area */}
      <Box height={pxToRem(200)}></Box>
      {/** rephrase button */}
      {toolPopOpen && (<HStack
        position={"fixed"}
        left={mousePos[0]}
        top={mousePos[1]}>
        <VStack
          width={pxToRem(238)}
          height={pxToRem(35)}
        >
          <Button
            border={"1px solid #3898FF"}
            bg={"#B4D8FF"}
            borderRadius={"10px"}
            leftIcon={
              <Image
                w={pxToRem(24)}
                objectFit="cover"
                src="/static/ToolAI/flash.png"
                alt="Doenba"
              />
            }
            onClick={(e) => {
              e.stopPropagation() // 防止触发editorModel中的click
              setToolPopOpen(false)
              updateAiType(AIDataType.rephrase)
              Promise.resolve().then(() => { // wait for event on
                eventBus.emit(EventType.Rephrase, selectContentArr)
              })
            }}
          >
            {t("editor-editormodel-phraseBtn")}
          </Button>
        </VStack>
      </HStack>)}
    </Box>
  )
}