import React, { useState, useRef, useEffect } from "react";

import {
  Box,
  VStack,
  Spinner,
  Tooltip,
  Textarea,
  Flex,
  Image,
  CloseButton, Button, Text,
} from "@chakra-ui/react";
import { WriterConversationMessage } from "../../types/Writer/Conversation";
import { WriterConversationService } from "../../services/writerConversationService";
import { ClientError } from "../../utils/clientError";
import PaperEditorConversationMessage from "./PaperEditorConversationMessage";
import { PaperOutlineNode } from "../../types/Writer/OutlineNode";
import { useRecoilValue } from "recoil";
import { selectedPaperId } from "../../atoms/rootAtom";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../utils/editor.ts";

interface PaperEditorToolPanelProps {
  outlineNodes: PaperOutlineNode[];
  selectedNodeId: number | null;
  setSelectedNodeId: (nodeId: number) => void;
}

enum TempMessageId {
  TEMP_REQUEST = -1,
  TEMP_RESPONSE = -2,
}

const PaperEditorConversationPanel: React.FC<PaperEditorToolPanelProps> = ({
                                                                             selectedNodeId,
                                                                             setSelectedNodeId,
                                                                           }) => {
  const paperId = useRecoilValue(selectedPaperId);
  const [isLoading, setIsLoading] = useState(false);
  const [msgEdited, setMsgEdited] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [writerConversationMessages, setWriterConversationMessages] = useState<
    WriterConversationMessage[]
  >([]);
  const [quotedMsg, setQuotedMsg] = useState<WriterConversationMessage | null>(
    null
  );
  const { t } = useTranslation()
  const [waitAiRes, setWaitAiRes] = useState<boolean>(false);

  // 让 Textarea 实现动态行高的 ref
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    async function fetchWriterConversationMessages() {
      try {
        setWriterConversationMessages([]);
        setIsLoading(true);

        const response =
          await WriterConversationService.fetchWriterConversationMessagesForPaper(
            paperId
          );
        setWriterConversationMessages(response);
      } catch (error) {
        new ClientError(error).toast();
      } finally {
        setIsLoading(false);
      }
    }

    if (!paperId) return;
    fetchWriterConversationMessages();
  }, [paperId]);

  // 滚动到底部
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [writerConversationMessages]);

  // 接收bot分段响应
  const handlePartialBotResponse = (partialResponse: string) => {
    try {
      setWriterConversationMessages((prevMessages) => {
        const existingNewMessage = prevMessages.find(
          (msg) => msg.id === TempMessageId.TEMP_RESPONSE
        );

        if (existingNewMessage) {
          return prevMessages.map((msg) => {
            if (msg.id === TempMessageId.TEMP_RESPONSE) {
              return {
                ...msg,
                message: msg.message + partialResponse,
              };
            }
            return msg;
          });
        } else {
          const newMessage: WriterConversationMessage = {
            id: TempMessageId.TEMP_RESPONSE,
            nodeId: selectedNodeId,
            paperId: paperId,
            message: partialResponse,
            sender: "bot",
          };
          return [...prevMessages, newMessage];
        }
      });
    } catch (error) {
      new ClientError(error).toast();
    }
  };

  // 接收到 bot 最终消息
  function handleWriterConversationMessagesResponse(
    conversationPieces: WriterConversationMessage[]
  ) {
    setWriterConversationMessages((prev) => [...prev, ...conversationPieces]);
  }

  // 发送消息
  const handleSendMessage = async () => {
    if (inputValue.trim() === "") return;

    const userMsg = inputValue;
    setInputValue(""); // 清空输入框
    setMsgEdited(false);

    try {
      setWaitAiRes(true)
      const tempMessage: WriterConversationMessage = {
        id: TempMessageId.TEMP_REQUEST,
        paperId: paperId,
        nodeId: selectedNodeId,
        sender: "user",
        message: userMsg,
      };
      setWriterConversationMessages([...writerConversationMessages, tempMessage]);

      await WriterConversationService.addToWriterConversation(
        paperId,
        selectedNodeId,
        userMsg,
        handlePartialBotResponse,
        handleWriterConversationMessagesResponse
      );

      setQuotedMsg(null);
      setWaitAiRes(false)
    } catch (error) {
      setWaitAiRes(false)
      new ClientError(error).toast();
    }
  };

  // 重新编辑消息
  const handleReEditMessage = (msg: string) => {
    setInputValue(msg);
    setMsgEdited(true);
  };

  // 引用某条消息
  const handleQuoteMessage = (msg: WriterConversationMessage) => {
    setQuotedMsg(msg);
  };

  // 动态行高逻辑（1~5 行）
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setInputValue(value);

    const textarea = textareaRef.current;
    if (!textarea) return;

    // 1) 每次变更时，先将行数重置为1，确保第一行始终显示
    textarea.rows = 1;

    // 2) 根据内容的实际高度计算行数
    const lineHeight = 16;  // 行高
    const maxRows = 5;      // 最大行数

    // 3) 计算当前需要的行数
    const currentRows = Math.floor(textarea.scrollHeight / lineHeight);

    // 4) 设置行数，确保最大不超过5行，且文本不会被裁剪
    if (currentRows >= maxRows) {
      textarea.rows = maxRows;  // 设置最大行数
      textarea.style.overflowY = "auto";  // 超过最大行数时显示滚动条
    } else {
      textarea.rows = currentRows || 1;  // 小于最大行数时根据内容自动调整
      textarea.style.overflowY = "hidden";  // 内容未满时不显示滚动条
    }
  };


  // 回车发送
  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && selectedNodeId) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // 判断输入框是否为空
  const isInputEmpty = inputValue.trim().length === 0;

  return (
    <Box h="full" w="full">

      <Box
        overflow="hidden"
        height="full"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"

      >
        {isLoading ? (
          <Box mt={5}>
            <Spinner/>
          </Box>
        ) : (
          <>
            {/* 消息列表 */}
            <VStack
              spacing={4}
              align="stretch"
              overflowY="auto"
              overflowX="hidden"       // ❶ 隐藏横向溢出
              flex={1}
              height="35vh"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#9EC3E8",
                  borderRadius: "2px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#2b6cb0",
                },
              }}
            >
              {writerConversationMessages.map((message) => {
                const outlineNodeName = "Testing node";
                return (
                  <PaperEditorConversationMessage
                    key={message.id}
                    message={message}
                    outlineNodeName={outlineNodeName}
                    isEdited={msgEdited && inputValue === message.message}
                    handleMessageClick={() => {
                      setSelectedNodeId && setSelectedNodeId(message.nodeId)
                    }}
                    handleReEditMessage={handleReEditMessage}
                    handleQuoteMessage={handleQuoteMessage}
                  />
                );
              })}
              {waitAiRes && (
                <Flex gap={2}>
                  <Image
                    w={pxToRem(30)}
                    h={pxToRem(30)}
                    objectFit="cover"
                    src="/static/loading-animate.svg"
                  />
                  <Text textColor={"#8E9ABC"} fontSize={"0.875rem"}>
                    {t('ai-send-waiting-text')}
                  </Text>
                </Flex>
              )
              }
              <div ref={messagesEndRef}/>
            </VStack>

            {/* 引用 + 输入框 + 发送按钮 区域 */}
            <Box
              border="1px solid #ccc"
              p={2}
              bg="white"
              borderRadius="10px"
              mt={4}
            >
              {/* 如果引用了某条消息，则显示 */}
              {quotedMsg && (
                <Flex
                  flexDir="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  w="full"
                  borderRadius="5px"
                  borderBottom="1px solid #ccc"
                  bg="#b4c1cf"
                  p={1}
                  mb={2}
                >
                  <Image
                    w={pxToRem(24)}
                    objectFit="cover"
                    src="/static/editor/link-square.svg"
                    alt="link"
                  />
                  <Box
                    w="100px"
                    flex="1 auto"
                    fontSize="12px"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    ml={2}
                  >
                    {quotedMsg.message}
                  </Box>
                  <CloseButton
                    w="20px"
                    h="20px"
                    onClick={() => setQuotedMsg(null)}
                  />
                </Flex>
              )}

              {/*
                如果输入框是空的 => row布局 => 按钮与文本框同行
                如果有文字 => column布局 => 按钮在下一行（同你原需求）
              */}
              <Flex
                direction={isInputEmpty ? "row" : "column"}
                alignItems={
                  isInputEmpty
                    ? "flex-end" // 当只有一行/空字符串时，可能想在垂直方向对齐
                    : "stretch"  // 当有文字时，改用 stretch，让 Textarea 左对齐
                }
                w="full"

              >

                <Textarea

                  ref={textareaRef}
                  rows={1}
                  p={0}
                  value={inputValue}
                  onChange={handleTextareaChange}
                  onKeyDown={handleKeyDown}
                  bg="white"
                  border="none"
                  resize="none"
                  _focusVisible={{ borderColor: "none" }}
                  placeholder={t('ai-chat-placeholder')}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#9EC3E8",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      background: "#2b6cb0",
                    },
                  }}
                  // 当输入框为空时，给它 flex="1" 以便按钮贴右侧
                  // 当有文字时，也可以让它自动占满整行
                  flex="1"
                />

                {/* 如果输入框是空的，就在同行显示按钮 */}
                {isInputEmpty && (
                  <Tooltip
                    label="You must select a node id first..."
                    isDisabled={!!selectedNodeId}
                  >
                    <Button
                      aria-label="Send button"
                      size="sm"
                      colorScheme="brand"
                      onClick={handleSendMessage}
                      isDisabled={!selectedNodeId}
                      rightIcon={
                        <Image
                          w="15px"
                          objectFit="cover"
                          src="/static/editor/msg-sending.svg"
                          alt="send-icon"
                        />
                      }
                      ml={2}     // 与输入框拉开一点距离
                      // w="120px" // 如果需要固定宽度，也可以直接写在这里
                    >
                      Send
                    </Button>

                  </Tooltip>
                )}
              </Flex>

              {/* 如果有文字，就另起一行放按钮 */}
              {!isInputEmpty && (
                <Flex w="full" justifyContent="flex-end" mt={2}>
                  <Tooltip
                    label="You must select a node id first..."
                    isDisabled={!!selectedNodeId}
                  >
                    <Button
                      aria-label="Send button"
                      size="sm"
                      colorScheme="brand"
                      onClick={handleSendMessage}
                      isDisabled={!selectedNodeId}
                      rightIcon={
                        <Image
                          w="15px"
                          objectFit="cover"
                          src="/static/editor/msg-sending.svg"
                          alt="send-icon"
                        />
                      }
                      //mr={10}     // 与输入框拉开一点距离
                      //w="90px" // 如果需要固定宽度，也可以直接写在这里
                    >
                      Send
                    </Button>

                  </Tooltip>
                </Flex>
              )}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PaperEditorConversationPanel;
