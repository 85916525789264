import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image, Modal, ModalBody,
  ModalContent,
  ModalOverlay,
  Text, useDisclosure,
  VStack,
  Link as ChakraLink,
} from "@chakra-ui/react";
import NodeTree from "../OutlineNodeTree/NodeTree.tsx";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { pxToRem } from "../../utils/editor.ts";
import useOutlineStore from "../../stores/modules/outline.ts";
import { PaperOutline } from "../../types/Writer/OutlineNode.ts";

/**
 * left outline overview
 */
interface LeftOutlineProps {
  leftBarWidth: string
  updateOutlineStructure: (outline: PaperOutline) => void
}
export const LeftOutline: React.FC<LeftOutlineProps> = (
  {
    leftBarWidth,
    updateOutlineStructure
  }
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { outline } = useOutlineStore();
  const [referencePanelOpenStatus, setReferencePanelOpenStatus] = useState<boolean>(false);
  const [refOutline, setRefOutline] = useState<string>("111111");
  const [refTone, setRefTone] = useState<string>("example123");
  const [deleteTipStr, setDeleteTipStr] = useState<string>("");
  const {
    isOpen: isOpenDeleteConfirmPanel,
    onOpen: onOpenDeleteConfirmPanel,
    onClose: onCloseDeleteConfirmPanel,
  } = useDisclosure();
  const {
    isOpen: isReferenceUploadPanel,
    onOpen: onOpenReferenceUploadPanel,
    onClose: onCloseReferenceUploadPanel,
  } = useDisclosure();

  /**
   * confirm delete reference
   */
  // const confirmDelete = () => {
  //  // todo: what
  // };

  return (
    <Flex
      h={"100%"}
      flexDir={"column"}
      w={leftBarWidth}
      justifyContent={"space-between"}
      boxShadow={"md"}
      position={"relative"}
      transition={"all 0.5s ease"}
      bgColor={"#FBFCFF"}
      overflow={'hidden'}
      borderRight={'2px solid #D3D7E2'}
    >
      <Flex flexDir={"column"}>
        <Center
          p={10}
          onClick={() => navigate("/editor")}
          cursor={"pointer"}
        >
          <Image
            w={pxToRem(144)}
            h={pxToRem(40)}
            objectFit="contain"
            src={"/static/doenba-logo-blue.png"}
          />
        </Center>
        <Flex
          flexDir={"column"}
          px={5}
          py={2}
        >
          <Button
            width={"100%"}
            pl={5}
            pr={5}
            w={"full"}
            bgColor={referencePanelOpenStatus ? "#ADD2FC" : "transparent"}
            leftIcon={
              <Image
                w={20 / 16 + "rem"}
                objectFit="cover"
                src="/static/editor/document-like.png"
              />
            }
            _hover={{ color: "#ADD2FC" }}
            rightIcon={
              <Image
                w={pxToRem(20)}
                objectFit="contain"
                src="/static/arrow-down.png"
              />
            }
            onClick={() =>
              setReferencePanelOpenStatus(!referencePanelOpenStatus)
            }
          >
            <Text m={1} as={"b"} color={"black"} fontSize={20 / 16 + "rem"}>
              {t("editor-editormodel-leftHeader")}
            </Text>
          </Button>
          {referencePanelOpenStatus && (
            <Box
              px={2}
              py={3}
              height={pxToRem(133)}
              mt={-1}
              borderRight={"1px solid #ccc"}
              borderLeft={"1px solid #ccc"}
              borderBottom={"1px solid #ccc"}
              borderRadius={"0 0 10px 10px"}
              transition={"all 0.5s ease"}
            >
              <Flex flexDir={"column"} justifyContent={"space-between"}>
                <VStack flexDir={"row"} spacing={"10px"}>
                  <Image
                    w={pxToRem(24)}
                    objectFit="cover"
                    src="/static/editor/text.png"
                    alt="no draft"
                  />
                  <Text as={"b"}>
                    {t("editor-editormodel-leftHeaderRefOutline")}
                  </Text>
                </VStack>
                <HStack>
                  <Text ml={pxToRem(30)} width={pxToRem(150)} isTruncated={true}>
                    {refOutline}
                  </Text>
                  <IconButton
                    size={"xs"}
                    aria-label="replace"
                    bgColor={"whiteAlpha.50"}
                    icon={
                      <Image
                        w={"12px"}
                        objectFit="cover"
                        src="/static/editor/Property 33.png"
                        alt="no draft"
                      />
                    }
                    onClick={() => {
                      setDeleteTipStr("Referenced Structure");
                      onOpenReferenceUploadPanel();
                    }}
                  />
                  <IconButton
                    size={"xs"}
                    aria-label="remove"
                    bgColor={"whiteAlpha.50"}
                    icon={
                      <Image
                        w={"1rem"}
                        objectFit="cover"
                        src="/static/editor/delete3.png"
                        alt="no draft"
                      />
                    }
                    onClick={() => {
                      setDeleteTipStr("Referenced Structure");
                      onOpenDeleteConfirmPanel();
                    }}
                  />
                </HStack>
                <VStack flexDir={"row"} spacing={"10px"}>
                  <Image
                    w={pxToRem(24)}
                    objectFit="cover"
                    src="/static/editor/text.png"
                    alt="no draft"
                  />
                  <Text as={"b"}>
                    {t("editor-editormodel-leftHeaderRefTone")}
                  </Text>
                </VStack>
                <HStack flexDir={"row"}>
                  <Text ml={pxToRem(30)} width={pxToRem(150)} isTruncated={true}>
                    {refTone}
                  </Text>
                  <IconButton
                    size={"xs"}
                    aria-label="replace"
                    bgColor={"whiteAlpha.50"}
                    icon={
                      <Image
                        w={"12px"}
                        objectFit="cover"
                        src="/static/editor/Property 33.png"
                        alt="no draft"
                      />
                    }
                    onClick={() => {
                      setDeleteTipStr("Referenced Tone");
                      onOpenReferenceUploadPanel();
                    }}
                  />
                  <IconButton
                    size={"xs"}
                    aria-label="remove"
                    bgColor={"whiteAlpha.50"}
                    icon={
                      <Image
                        w={"1rem"}
                        objectFit="cover"
                        src="/static/editor/delete3.png"
                        alt="no draft"
                      />
                    }
                    onClick={() => {
                      setDeleteTipStr("Referenced Tone");
                      onOpenDeleteConfirmPanel();
                    }}
                  />
                </HStack>
              </Flex>
            </Box>
          )}
        </Flex>
        <Box
          mt={5}
          h={`calc(100vh - ${
            referencePanelOpenStatus ? pxToRem(502) : pxToRem(373)
          })`}
        >
          {/** outline structure */}
          {outline && (
            <Box
              transition={'all .5s'}
              height={`calc(100vh - ${
                referencePanelOpenStatus ? pxToRem(502) : pxToRem(373)
              })`}>
              <NodeTree updateOutlineStructure={updateOutlineStructure}
            />
            </Box>
          )}
        </Box>
        <VStack justifyContent={'center'}>
          <Button
            mt={3}
            w={pxToRem(192)}
            px={0}
            leftIcon={
              <Image
                w={pxToRem(20)}
                objectFit="cover"
                src="/static/editor/overview.png"
                alt="no draft"
              />
            }
            bg={'#FBFCFF'}
            fontSize={18}
          >
            {t("editor-editormodel-leftOverview")}
          </Button>
          <Button
            bg={'#FBFCFF'}
            mt={3}
            px={0}
            w={pxToRem(192)}
            fontSize={pxToRem(18)}
            leftIcon={
              <Image
                w={pxToRem(20)}
                objectFit="cover"
                mr={'auto'}
                src="/static/editor/Export2.png"
                alt="no draft"
                position={'relative'}
                left={pxToRem(-10)}
              />
            }
          >
            {t("editor-editormodel-leftExport")}
          </Button>
        </VStack>
        <Center mt={7}>
          <ChakraLink mb={5}
                      color={'#333333'}
                      fontFamily={'Lato'}
                      variant="underline" textDecoration={'underline'} fontWeight={'bold'} fontSize={pxToRem(16)}>
            {t("editor-editormodel-leftTutorials")}
          </ChakraLink>
        </Center>
      </Flex>

      {/** Reference Article upload modal */}
      <Modal
        isOpen={isReferenceUploadPanel}
        onClose={onCloseReferenceUploadPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={730}
          height={404}
          top={200}
          bg={'#FBFCFF'}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Text as="b" fontSize={"1rem"}>
                {t("editor-editormodel-leftHeader")}
              </Text>
              <Text textColor={"#8E9ABC"}>
                {t("editor-editormodel-leftUploadRef")}
              </Text>
              <Center m={5}>
                <Flex
                  width={"100%"}
                  height={pxToRem(192)}
                  flexDir={"column"}
                  border={"1px solid #ccc"}
                >
                  <Center mt={3}>
                    <Image
                      w={pxToRem(40)}
                      objectFit="cover"
                      src="/static/editor/export.png"
                    />
                  </Center>
                  <Center>
                    <Text as="b" textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUpload")}
                    </Text>
                  </Center>
                  <Center>
                    <Text textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUploadFile")}
                    </Text>
                  </Center>
                  <Center>
                    <Button
                      m={3}
                      w={pxToRem(139)}
                      h={pxToRem(48)}
                      bgColor={"#1D8AFF"}
                      textColor={"white"}
                      _hover={{ color: "#1D8AFF", textColor: "white" }}
                    >
                      {t("editor-editormodel-leftBtnSelect")}
                    </Button>
                  </Center>
                </Flex>
              </Center>
              <Button
                variant="outline"
                ml={550}
                mt={3}
                w={pxToRem(139)}
                h={pxToRem(48)}
                color={"#1D8AFF"}
                borderColor={"#1D8AFF"}
                onClick={() => {
                  setRefOutline("111");
                  setRefTone("112");
                  onCloseReferenceUploadPanel();
                }}
              >
                {t("editor-editormodel-leftBtnApply")}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      {/** delete referenced structure */}
      <Modal
        isOpen={isOpenDeleteConfirmPanel}
        onClose={onCloseDeleteConfirmPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={pxToRem(551)}
          height={pxToRem(345)}
          top={pxToRem(200)}
          borderRadius={pxToRem(24)}
          border={"1px solid #ccc"}
          bg={'#FBFCFF'}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Center mt={5}>
                <Image
                  w={pxToRem(100)}
                  objectFit="cover"
                  src="/static/editor/delete2.png"
                />
              </Center>
              <Center mt={30}>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel1")}
                  {/*{t("editor-editormodel-leftDeletePanel2")}*/}
                </Text>
              </Center>
              <Center>
                <Text fontWeight={'bold'} style={{ color: "#1D8AFF" }}>{deleteTipStr}</Text>
              </Center>
              <Center>
                <Text textColor={"black"}>
                {t("editor-editormodel-leftDeletePanel3")}
                </Text>
              </Center>
              <HStack p={10} spacing={150}>
                <Button
                  bgColor={"#9399AB"}
                  width={pxToRem(139)}
                  height={pxToRem(48)}
                  borderRadius={pxToRem(12)}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnCancel")}
                </Button>
                <Button
                  bgColor={"#1D8AFF"}
                  width={pxToRem(139)}
                  height={pxToRem(48)}
                  borderRadius={pxToRem(12)}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    // todo
                    // updateOutline(confirmDelete());
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("yes-label")}
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}