import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  Input,
  Spinner,
  Text,
  VStack
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { PaperService } from "../services/paperService";
import { ClientError } from "../utils/clientError";
import { Paper, UpdatePaperRequest } from "../types/Writer/Paper";
import { OutlineNode, PaperOutline } from "../types/Writer/OutlineNode";
import { useTranslation } from "react-i18next";
import { formatString, getTreeNodes, listToTree, } from "../types/Writer/NodesFunc";
import ToolAIBox from "../components/PaperEditor/ToolAIBox";
import EditorModelRight from "./EditorModelRight";
import { useSetRecoilState } from "recoil";
import { citationCount, paperResources, selectedPaperId } from "../atoms/rootAtom";
import { CitationWithCount } from "../types/Writer/BibContent";
import { getNodesWordsCount, pxToRem } from "../utils/editor.ts";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { LeftOutline } from "../components/PaperEditor/LeftOutline.tsx";
import { EmptySection } from "../components/PaperEditor/EmptySection.tsx";
import { SectionContent } from "../components/PaperEditor/SectionContent.tsx";
import useAiTypeStore from "../stores/modules/aiType.ts";
import { AIPanel } from "../components/PaperEditor/AIPanel/AIPanel.tsx";
import useOutlineStore from "../stores/modules/outline.ts";
import { useEditorHook } from "../hooks/editor/useEditor.hook.ts";
import { cloneDeep } from "lodash";
import UserMenu from "../components/NavBar/Components/UserMenu.tsx";
import { useAuth0 } from "@auth0/auth0-react";

const EditorModel: React.FC = () => {
  const { t } = useTranslation();
  const listRef = useRef(null);
  const aiPanelRef = useRef(null);
  //----------------------------------------------------
  const { paperId } = useParams();
  const [usePaperId, setUsePaperId] = useState<string>("");
  const [paper, setPaper] = useState<Paper>(null);
  const [paperName, setPaperName] = useState<string>("");
  const [nodeWords, setNodeWords] = useState<number>(0);
  const [totalWords, setTotalWords] = useState<number>(0);

  const idRef = useRef<number>(undefined);
  const setCitationCount = useSetRecoilState(citationCount);
  const setPaperId = useSetRecoilState(selectedPaperId);
  const setResources = useSetRecoilState(paperResources);
  const aiType = useAiTypeStore((state) => state.aiType)
  const { outline, nextId, setOutline, setNextId, currentSectionIndex, outlineIsChange, setOutlineIsChange, nodeList, setNodeList, setToolPopOpen } = useOutlineStore();
  const { syncDOMToNodes } = useEditorHook()
  // file save status
  const [saving, setSaving] = useState<boolean>(false);
  const [saveComplete, setSaveComplete] = useState<boolean>(false);
  // edit paper name
  const [editStatus, setEditStatus] = useState<boolean>(false);
  const { user, isAuthenticated } = useAuth0();

  const leftBarWidth: string = "15%";
  const rightBarWidth: string = "23%";
  useEffect(() => {
    async function fetchPaper() {
      try {
        const newPaper = await PaperService.getPaperById(usePaperId);
        setPaper(newPaper);
        setOutline(newPaper?.outline);
        setNextId(newPaper?.outline?.nextId)
        setPaperName(newPaper?.name);
        setResources(newPaper?.resources);
        setPaperId(newPaper?.id);

        const citationCounts: CitationWithCount[] = newPaper.resources.map(resource => ({
          id: resource.id,
          count: 0
        }));

        setCitationCount(citationCounts);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    if (!usePaperId) return;
    fetchPaper();
  }, [usePaperId]);

  /**
   * when outline change, update nodeList
   * wait for optimized, whether to change nodeList first, then modify outline when saving
   */
  useEffect(() => {
    if (!outline) return;
    const list = getTreeNodes([outline.nodes[currentSectionIndex]]);
    const count = getNodesWordsCount(getTreeNodes(outline?.nodes))
    setTotalWords(count);
    if (list?.length) {
      idRef.current = list[0].id;
    }
    setNodeList(list);
  }, [currentSectionIndex, outline]);

  useEffect(() => {
    if (!nodeList) return;
    setNodeWords(getNodesWordsCount(nodeList));
  }, [nodeList]);

  useEffect(() => {
    if (!paperId) return;
    setUsePaperId(paperId);
  }, [paperId]);

  /**
   * update editor block request
   */
  const updateOutline = async (updateOutline: boolean) => {
    // todo:  许多地方并未修改当前section的nodeList，不需要多一步setOutline
    const newOutline = cloneDeep(outline)
    if (updateOutline) {
      const newSection = listToTree(nodeList)[0]
      if (currentSectionIndex > -1) {
        newOutline.nodes[currentSectionIndex] = newSection
      }
      newOutline.nextId = nextId
      setOutline({ ...newOutline })
      syncDOMToNodes(newOutline.nodes);
    }
    if (outlineIsChange) {
      setOutlineIsChange(false)
      const updateRequest: UpdatePaperRequest = {
        name: paperName,
        outline: newOutline,
        authors: paper.authors,
        content: paper.content,
        abstract: paper.abstract,
        keywords: paper.keywords,
        draft: paper.draft,
        resources: paper.resources,
      }
      try {
        setSaving(true)
        await PaperService.updatePaper(
          usePaperId,
          updateRequest
        )
        // setOutline(newPaper?.outline);
        // setPaperName(newPaper?.name);
        // setPaper(newPaper);
        setSaving(false)
        setSaveComplete(true)
        setTimeout(() => { // hide status after 1s
          setSaveComplete(false)
        }, 1000)
      } catch (error) {
        setSaving(false)
        new ClientError(error).toast();
      }
    }
  };

  /**
   * only update outline structure
   */
  const updateOutlineStructure = async (updateOutline: PaperOutline) => {
    try {
      setSaving(true)
      await PaperService.updatePaper(
        usePaperId,
        {
          name: paperName,
          outline: updateOutline,
          authors: paper.authors,
          content: paper.content,
          abstract: paper.abstract,
          keywords: paper.keywords,
          draft: paper.draft,
          resources: paper.resources,
        }
      )
      setSaving(false)
    } catch (err) {
      setSaving(false)
      new ClientError(err).toast();
    }
  }
  //-------------------------------------------------------
  return (
    <>
      <HStack h={"100%"} w={"100%"} style={{ overflow: "auto" }}
              gap={0}
              bg={'#fbfcff'}
              onClick={(e) => {
                if (typeof (e.target as HTMLElement).onclick === "function") {
                  return;
                }
                if (!listRef.current?.contains(e.target) && !aiPanelRef.current?.contains(e.target)) {
                  /**
                   * 引起不必要更新，待优化 对某些按钮进行stopPropagation
                   */
                  updateOutline(true)
                  setToolPopOpen(false)
                }
              }}
      >
        {/** left outline */}
        <LeftOutline
          leftBarWidth={leftBarWidth}
          updateOutlineStructure={updateOutlineStructure}
        />

        {/** center work area */}
        <Flex
          display={"flex"}
          flexDir={"column"}
          flex={"1 auto"}
          h={"100%"}
          bg={'#fbfcff'}
          gap={'1rem'}
          maxW={`calc(100% - ${leftBarWidth})`}
        >
          <HStack mt={'1rem'} px={'0.75rem'} minHeight={'60px'}>
            {/** paper name */}
            <Flex gap={6} alignItems={"center"}
                  bg={'#FFFFFF'}
                  mx={'auto'}
                  px={'1rem'}
                  flex={1}
                  borderRadius={pxToRem(12)}
                  border={'2px solid #D3D7E2'}
                  justifyContent={"start"}>
              <Flex gap={3} alignItems={"center"} h={50} pl={2}>
                {editStatus ?
                  <Input defaultValue={paperName}
                         autoFocus={true}
                         focusBorderColor={'gray.200'}
                         onChange={(e) => {
                           setPaperName(e.target.value)
                         }}
                         onBlur={() => {
                           setEditStatus(false);
                           setOutlineIsChange(true)
                           updateOutline(false)
                         }}
                  />
                  : <Text h={pxToRem(50)} as={"b"} lineHeight={pxToRem(50)} fontSize={pxToRem(20)}
                          onClick={() =>{
                    setEditStatus(true);
                  }}>
                    {paperName}
                  </Text>}
              </Flex>
              {/** saving display */}
              {saving && <Flex alignItems={"center"}>
                  <Text color={"#666666AA"}>{t('save-loading-text')}</Text>
                  <Spinner
                      thickness="2px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="sm"
                  />
              </Flex>}
              {saveComplete && <Flex alignItems={"center"}>
                  <Text color={"#666666AA"}>{t('save-completed-text')}</Text>
                  <CheckCircleIcon
                      color="green.500"
                      w="12"
                  />
              </Flex>}
            </Flex>
            <Flex flexDir={'row'}
                  boxShadow={'1px 4px 10px #AAB2C8'}
                  width={pxToRem(50)}
                  height={pxToRem(50)}
                  borderRadius={'50%'}
                  justify="flex-end" align='center'>
              {isAuthenticated && user && (
                <UserMenu user={user}/>
              )}
            </Flex>
          </HStack>
          <HStack flex={1} minHeight={'calc(100% - 180px)'}>
            <HStack width={`calc(100% - ${rightBarWidth})`} height={'100%'}>
              <VStack width={`calc(100% - ${aiType ? pxToRem(292) : '0px'})`} height={'100%'}
                      transition={"width 0.5s"}
                      gap={'1rem'}>
                <ToolAIBox />
                {/** section editor */}
                <HStack
                  justifyContent="flex-end"
                  w={'full'}
                  height={`calc(100% - ${pxToRem(80)})`}
                  gap={0}
                  position={'relative'}
                >
                  <Box
                    height={"100%"}
                    width={"100%"}
                    justifyContent="flex-start"
                    overflow={"auto"}
                  >
                    {outline?.nodes[currentSectionIndex] == undefined ? (<EmptySection/>) : (
                      <Flex
                        flexDir={"column"}
                        width={'96%'}
                        maxWidth={pxToRem(883)}
                        minHeight={'100%'}
                        px={'1rem'}
                        m={'auto'}
                        bg={'#FFFFFF'}
                        borderRadius={'10px'}
                        border={'2px solid #D3D7E2'}
                        overflowY={"hidden"}
                      >
                        <SectionContent
                          listRef={listRef}
                          updateOutline={updateOutline}
                        />
                        {/** total words */}
                        <Center position={'absolute'} bottom={pxToRem(10)}
                                left={'50%'} transform={'translateX(-50%)'}>
                          <Center
                            border={"1px solid #8E9ABC"}
                            borderRadius={"10px"}
                            bg={'#FFFFFF'}
                            minWidth={pxToRem(143)}
                            height={pxToRem(25)}
                            px={pxToRem(10)}
                            fontSize={pxToRem(12)}
                            fontWeight={'bold'}
                            fontFamily={'Lato'}
                            color={'#8E9ABC'}
                          >
                            {formatString("{0}/{1} words", nodeWords, totalWords)}
                          </Center>
                        </Center>
                      </Flex>
                    )}
                  </Box>
                </HStack>
              </VStack>
              {/** AiPanel rephrase/suggestion/reorganize */}
              {aiType && (
                <VStack
                  width={pxToRem(304)}
                  height={`calc(100% - 6rem)`}
                  overflowY={"auto"}
                  overflowX={"hidden"}
                  pl={'1rem'}
                  pr={'1rem'}
                  mt={'1rem'}
                  ref={aiPanelRef}
                  transition={"all 0.5s"}
                >
                  <AIPanel updateOutline={updateOutline} />
                </VStack>
              )}
            </HStack>
            {/** right area */}
            <EditorModelRight
              rightBarWidth={rightBarWidth}
              onAddLabel={(node: OutlineNode[]) => {
                return console.log(node);
              }}
            />
          </HStack>
        </Flex>
      </HStack>
    </>
  );
};

export default EditorModel;
