import { Box, Button, Image, Checkbox, Flex, Tooltip } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { selectedCrossReferences } from '../../atoms/rootAtom';
import { CrossReference, PaperOutline, PaperOutlineNode } from '../../types/Writer/OutlineNode';
import { getCrossReferenceNodes } from '../../utils/flatternPaperNodes';
import LatexWrapper from '../LatexWrapper';
import { pxToRem } from "../../utils/editor.ts";

enum REFERENCE_TYPE {
    figure = 'figure',
    table = 'table',
    equation = 'equation',
    paragraph = 'paragraph',
    section = 'section'
}

type CrossReferencePanelProps = {
    outline: PaperOutline
}

const CrossReferencePanel: React.FC<CrossReferencePanelProps> = ({ outline }) => {
    const [currentReferenceType, setCurrentReferenceType ] = useState<REFERENCE_TYPE>(REFERENCE_TYPE.figure);
    const [selectedReferences, setSelectedReferences] = useRecoilState(selectedCrossReferences);
    const [crossRef, setCrossRef] = useState<CrossReference | null>(undefined);

    useEffect(() => {
        if (outline && outline.nodes) {
            setCrossRef(getCrossReferenceNodes(outline.nodes))
        }
    }, [outline])

    const toggleSelection = (reference: PaperOutlineNode) => {
        setSelectedReferences((prevSelected) => {
            const exists = prevSelected.some((ref) => ref.id === reference.id);
            if (exists) {
                return prevSelected.filter((ref) => ref.id !== reference.id);
            } else {
                return [...prevSelected, reference];
            }
        });
    }

    const crossReferencePillarOnClick = (piller: REFERENCE_TYPE) => {
        setCurrentReferenceType(REFERENCE_TYPE[piller])
    }

    const insertReference = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);
        range.deleteContents();

        selectedReferences.map(ref => {
            const span = document.createElement("span");
            span.textContent = ref.content;
            range.insertNode(span);
            const newRange = document.createRange();
            newRange.setStartAfter(span);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
        })
    }

    return (
        <Flex flexDir={'column'}>
            <Flex flexDir={'row'} pb={1} px={'0.5rem'}>
                <Button size={'xs'}
                    flex={1}
                    mr={1}
                        color={"#333"}
                        borderRadius={pxToRem(10)}
                    border={'1px solid rgba(56, 152, 255, 0.38)'}
                        _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                    bg={currentReferenceType === 'figure' ? 'rgba(56, 152, 255, 0.38)' : null}
                    onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.figure)}>
                    Figure
                </Button>
                <Button size={'xs'}
                    flex={1}
                    mr={1}
                        color={"#333"}
                        borderRadius={pxToRem(10)}
                    border={'1px solid rgba(56, 152, 255, 0.38)'}
                        _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                    bg={currentReferenceType === 'table' ? 'rgba(56, 152, 255, 0.38)' : null}
                    onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.table)}>
                    Table
                </Button>
                <Button size={'xs'}
                    flex={1}
                    mr={1}
                        color={"#333"}
                        border={'1px solid rgba(56, 152, 255, 0.38)'}
                        borderRadius={pxToRem(10)}
                        _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                    bg={currentReferenceType === 'equation' ? 'rgba(56, 152, 255, 0.38)' : null}
                    onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.equation)}>
                    Equation
                </Button>
                <Button size={'xs'}
                    flex={1}
                    mr={1}
                        color={"#333"}
                        border={'1px solid rgba(56, 152, 255, 0.38)'}
                    borderRadius={pxToRem(10)}
                        _hover={{ bg: 'rgba(56, 152, 255, 0.38)' }}
                    bg={currentReferenceType === 'section' ? 'rgba(56, 152, 255, 0.38)' : null}
                    onClick={() => crossReferencePillarOnClick(REFERENCE_TYPE.section)}>
                    Section
                </Button>
            </Flex>
            <Flex flexDir={'column'} overflow={'auto'} h={'125px'}>
                {crossRef && crossRef?.section.map((ref, index) => (
                    <Box key={index}>
                        { currentReferenceType === REFERENCE_TYPE.section &&
                            <Flex py={2}>
                                <Checkbox 
                                    isChecked={selectedReferences.includes(ref)}
                                    onChange={() => toggleSelection(ref)}>
                                    <Tooltip hasArrow label={JSON.stringify(ref.name)} aria-label='Description' style={{
                                        display: 'block',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                    }}>
                                        <span>{ref.label || ref.name}</span>
                                    </Tooltip>
                                </Checkbox>
                            </Flex>
                        }
                    </Box>
                ))}
                {crossRef &&  crossRef?.equation.map((ref, index) => (
                    <Box key={index}>
                        { currentReferenceType === REFERENCE_TYPE.equation &&
                            <Flex py={2}>
                                <Checkbox 
                                    isChecked={selectedReferences.includes(ref)}
                                    onChange={() => toggleSelection(ref)}>
                                        <Tooltip hasArrow label={
                                            <Box>
                                                <LatexWrapper content={ref.content}></LatexWrapper>
                                            </Box>
                                        } aria-label='Description'>
                                            {ref.name !== '' ? ref.name : ref.label}
                                        </Tooltip>
                                </Checkbox>
                            </Flex>
                        }
                    </Box>
                ))}
                {crossRef &&  crossRef?.figure.map((ref, index) => (
                    <Box key={index}>
                        { currentReferenceType === REFERENCE_TYPE.figure && 
                            <Flex py={2}>
                                <Checkbox 
                                    isChecked={selectedReferences.includes(ref)}
                                    onChange={() => toggleSelection(ref)}>
                                        <Tooltip hasArrow label={
                                            <Box>
                                                <Image 
                                                    src={`data:image/jpeg;base64,${ref.content}`} 
                                                    alt="Reference Preview" 
                                                    maxWidth="200px"
                                                    maxHeight="200px"
                                                />
                                            </Box>
                                        } aria-label='Description'>
                                            {ref.name !== '' ? ref.name : ref.caption}
                                        </Tooltip>
                                </Checkbox>
                            </Flex>
                    }
                    </Box>
                ))}
                {crossRef &&  crossRef?.table.map((ref) => (
                    <Box key={ref.id}>
                        { currentReferenceType === REFERENCE_TYPE.table &&
                            <Flex py={2}>
                                <Checkbox 
                                    isChecked={selectedReferences.includes(ref)}
                                    onChange={() => toggleSelection(ref)}>
                                        <Tooltip hasArrow label={
                                            <Box>
                                                <LatexWrapper content={ref.content}></LatexWrapper>
                                            </Box>
                                        } aria-label='Description'>
                                            {ref.name !== '' ? ref.name : ref.caption}
                                        </Tooltip>
                                </Checkbox>
                            </Flex>
                        }
                    </Box>
                ))}
            </Flex>
            <Flex flexDir={'row'} justifyContent={'space-between'} pt={1} pb={2}>
                <Box>
                    <Button w={pxToRem(99)} h={pxToRem(30)}
                        size={"sm"}
                        variant={'outline'}
                        borderColor={'#d3d7e2'}
                        leftIcon={
                            <Image bg={'#C9D9E8'} w="1rem" p={'2px'} borderRadius={pxToRem(5)} src="/static/editor/login.svg" alt="Refer button" />
                        }
                        onClick={insertReference}
                    >
                        Refer
                    </Button>
                </Box>
            </Flex>
        </Flex>
    )
}

export default CrossReferencePanel;