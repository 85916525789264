import React, { useState } from 'react';
import {
  VStack,
  HStack,
  Text,
  Button,
  Image,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverCloseButton, PopoverBody, List, ListItem, PopoverFooter, Box, IconButton
} from '@chakra-ui/react';
import { pxToRem } from "../../../utils/editor.ts";
import { OutlineNodeType, PaperOutlineNode } from "../../../types/Writer/OutlineNode.ts";
import { PaperService } from "../../../services/paperService.ts";
import { getTreeNodes } from "../../../types/Writer/NodesFunc.ts";
import { ClientError } from "../../../utils/clientError.ts";
import {
  ReorganizeSectionResultVersion,
  ReorganizeStructureRender
} from "../../../types/Writer/ReorganizeSectionResult.ts";
import { useTranslation } from "react-i18next";
import useOutlineStore from "../../../stores/modules/outline.ts";
import { cloneDeep } from "lodash";

interface ReorganizeProps {
  result: ReorganizeSectionResultVersion
  index: number
  currentLength: string
  currentDegree: string
  updateOutline: (updated: boolean) => void
}

export const Reorganize: React.FC<ReorganizeProps> = (
  {
    index,
    result,
    currentDegree,
    currentLength,
    updateOutline
  }) => {
  const { t } = useTranslation()
  const { outline, currentSectionIndex, setOutline } = useOutlineStore()
  const [isReWrite, setIsReWrite] = useState<boolean>(false);
  const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
  const [newSectionStructure, setNewSectionStructure] = useState<PaperOutlineNode>(null);
  const [structureTemp, setStructureTemp] = useState<ReorganizeStructureRender>({
    title: '',
    desc: '',
    nodeList: []
  });
  const [nodeListTemp, setNodeListTemp] = useState<PaperOutlineNode[]>([]);
  const [isReWriteLoading, setIsReWriteLoading] = useState<boolean>(false);

  /**
   * popup replace/rewrite button click
   */
  const replaceOrRewrite = async () => {
    if (isReWrite) {
      const newOutline = cloneDeep(outline)
      newOutline.nodes[currentSectionIndex] = newSectionStructure
      setOutline({ ...newOutline })
      setIsReWriteLoading(true)
      await updateOutline(false)
      setIsPopOpen(false)
      setIsReWriteLoading(false)
    } else {
      rewriteSectionReq()
    }
  }

 /**
  * call rewrite api
  */
  const rewriteSectionReq = async () => {
    setIsReWriteLoading(true)
    try {
      const index = outline.nodes?.findIndex(item => item.id === outline.nodes[0].id)
      const result = await PaperService.rewriteSection(outline,
        structureTemp.structure, currentDegree, currentLength, index);
      setNewSectionStructure(result.sectionNode)
      const list = getTreeNodes([result.sectionNode]);
      setNodeListTemp(list);
      setIsReWrite(true)
      setIsReWriteLoading(false)
    } catch (error) {
      setIsReWriteLoading(false)
      new ClientError(error).toast();
    }
  }

  /**
   * show reorganize modal, new structure
   */

  const showNewStructure = (reorganize: ReorganizeSectionResultVersion, index: number) => {
    setStructureTemp(
      {
        title: `Recommend Structure ${index + 1}`,
        desc: reorganize.reorganize_strategy_and_advantage,
        nodeList: getTreeNodes([reorganize.reorganized_structure]),
        structure: reorganize.reorganized_structure
      }
    );
    setIsPopOpen(true)
  }

  return (
    <Flex width={pxToRem(248)}
          alignItems={"center"}
          justifyContent={"start"}>
      <Popover isOpen={isPopOpen} placement="left">
        <PopoverTrigger>
          <VStack onClick={(e) => {
            e.stopPropagation()
            showNewStructure(result, index)
          }} cursor={"pointer"}>
            <Text
              width={pxToRem(248)}
              aria-multiline={true}
              fontSize={16}
              fontWeight={700}
            >
              Recommend Structure {index + 1}
            </Text>
            <Text
              width={pxToRem(248)}
              aria-multiline={true}
              fontSize={14}
              fontWeight={400}
            >
              {result.reorganize_strategy_and_advantage}
            </Text>
          </VStack>
        </PopoverTrigger>
        <PopoverContent
          position={'relative'}
          p={0} border={'2px solid #D3D7E2'} zIndex={-1} width={pxToRem(600)}>
          {isReWriteLoading && (
            <VStack
              width={pxToRem(600)}
              height={'100%'}
              position={'absolute'}
              justifyContent={'center'}
              background={'#FFFFFFBB'} backdropFilter={'blur(6px)'}
            >
              <Image
                mt={4}
                w={pxToRem(60)}
                objectFit="cover"
                src="/static/loading-animate.svg"
                transform={"scale(2)"}
              />
              <Text color={'#8E9ABC'} fontSize={pxToRem(20)} mt={4}>{ t('editor-editormodel-reorganize-loading')}</Text>
              <IconButton
                bg="transparent"
                size={"xs"}
                mt={'1rem'}
                onClick={(e) => {
                  e.stopPropagation()
                  setIsReWriteLoading(false);
                  setIsReWrite(false)
                }}
                icon={
                  <Image
                    w={pxToRem(32)}
                    objectFit="contain"
                    src={"/static/ToolAI/cancelCal.svg"}
                    alt="cancel"
                  />
                }
                aria-label="cancel"
              />
            </VStack>
          )}
          <PopoverHeader border={0}>
            <Image
              w={pxToRem(144)}
              h={"40px"}
              objectFit="contain"
              src={"/static/doenba-logo-blue.png"}
            />
          </PopoverHeader>
          <PopoverCloseButton onClick={() => setIsPopOpen(false)}/>
          <PopoverBody
            maxH={pxToRem(400)}
            minH={pxToRem(100)}
            padding={pxToRem(32)}
            overflow={'auto'}
          >
            {!isReWrite ? (<VStack alignItems={"start"}>
                <Text fontSize={pxToRem(20)} fontWeight={"bold"} color={"#333333"}>
                  {structureTemp.title}
                </Text>
                <Text fontSize={"14px"} my={"10px"}>
                  {structureTemp.desc}
                </Text>
                <List>
                  {structureTemp?.nodeList?.map((child, index) =>
                    (
                      <ListItem
                        flex={"column"}
                        key={index}
                        my={"10px"}
                      >
                        <HStack
                          alignItems={"flex-start"}
                          pl={(child.level !== 2 ? (28 * (child.level - 2)).toString() : 0) + "px"}
                        >
                          {child.type === OutlineNodeType.Paragraph ? (
                            <VStack alignItems={"start"}>
                              <Text
                                color={"#333333"}
                                fontWeight={"bold"}
                                fontSize={pxToRem(18)}>{'Paragraph' + child.paragraph_index}</Text>
                              <List
                                styleType={"disc"}
                                pl={(child.level !== 2 ? (28 * (child.level - 2)).toString() : 0) + "px"}>
                                {child.key_points?.map((key_point, point_index) => (
                                  <ListItem key={point_index}>
                                    <Text color={"#444"}
                                          fontSize={"14px"}>KeyPoint {key_point}</Text>
                                  </ListItem>
                                ))}
                              </List>
                            </VStack>
                          ) : (
                            <Text
                              textColor={"333333"}
                              width={'100%'}
                              minHeight={pxToRem(20)}
                              fontSize={pxToRem(18)}
                              fontWeight={"bold"}
                            >
                              {child.name}
                            </Text>)}
                        </HStack>
                      </ListItem>))
                  }
                </List>
              </VStack>
            ) : (
              <List>
                {nodeListTemp?.map((child, index) =>
                  (
                    <ListItem
                      flex={"column"}
                      key={child.id.toString() + child.name + index}
                    >
                      <HStack
                        alignItems={"flex-start"}
                        pl={(56 * (child.level - 1)).toString() + "px"}
                      >
                        {child.type == "paragraph" ? (
                          <Text
                            fontSize={"16px"}>{child.content}</Text>
                        ) : (
                          <Text
                            textColor={
                              child.level == 1 ? "#1D8AFF" : "#8E9ABC"
                            }
                            width={'100%'}
                            minHeight={pxToRem(20)}
                            fontSize={"16px"}
                            fontWeight={"bold"}
                          >
                            {child.name}
                          </Text>)}
                      </HStack>
                    </ListItem>))
                }
              </List>
            )}
            <Box position={'absolute'} bottom={'60px'} left={0}
              width={"calc(100% - 2rem)"} height={'100px'} bg={'linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1))'}></Box>
          </PopoverBody>
          <PopoverFooter textAlign={"right"} border={0}>
            <Button
              width={pxToRem(100)}
              height={pxToRem(34)}
              fontSize={'1rem'}
              isLoading={isReWriteLoading}
              textColor={"#FFFFFF"}
              border={"1px solid #1D8AFF"}
              bgColor={"#1D8AFF"}
              _hover={{ bg: '#1D8AFF' }}
              mt={2}
              onClick={(e) => {
                e.stopPropagation()
                replaceOrRewrite()
              }}
            >
              {isReWrite ? t("editor-editormodel-reorganize-replace") :
                t("editor-editormodel-reorganize-rewrite")}
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Popover>
    </Flex>
  )
};

export default Reorganize;