// Writer.tsx
import {
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem,
  Heading,
  Modal,
  Image,
  useDisclosure,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Card,
  CardBody,
  Center,
  Container,
  HStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PaperCard from '../components/PaperCard/PaperCard';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../services';
import { ClientError } from '../utils/clientError';
import { PaperService } from '../services/paperService';
import { useAuth0 } from "@auth0/auth0-react";
import { Paper } from '../types/Writer/Paper';
import { EditIcon } from '@chakra-ui/icons';
import SideNav from '../components/NavBar/SideNav';
// import { MathJax } from 'better-react-mathjax';
// import { OutlineNode } from '../types/Writer/OutlineNode';
import SearchBar from '../components/SearchBar/SearchBar';
import LangSelectV2 from '../components/NavBar/LangSelectV2';
import UserMenu from '../components/NavBar/Components/UserMenu';
import TemplateService from '../services/TemplateService';
import PreviewModalPanel from '../components/PreviewModalPanel/PreviewModalPanel';
import DoenbaSelect from '../components/DoenbaSelect/DoenbaSelect';
import DocumentService from '../services/DocumentService';
import { CreatePaper } from "../components/PaperEditor/CreatePaper.tsx";
import { pxToRem } from "../utils/editor.ts";

interface DocumentWithThumbnail {
  thumbnailUrl?: string;
}

export enum NewIntent {
  NORMAL_DRAFT = 'NORMAL_DRAFT',
  NO_DRAFT = 'NO_DRAFT'
}

interface DocumentExtended extends Paper, DocumentWithThumbnail {
}

const Writer: React.FC = () => {
  const { isOpen: isPaperViewerOpen, onOpen: onPaperViewerOpen, onClose: onPaperViewerClose } = useDisclosure();
  const navigate = useNavigate();
  const [papers, setPapers] = useState<Paper[]>([]);
  const [selectedPaper, setSelectedPaper] = useState<Paper>(null);
  const toast = useToast();
  const { isOpen: isNewDocumentOpen, onOpen: onNewDocumentOpen, onClose: onNewDocumentClose } = useDisclosure();
  const [newIntent, setNewIntent] = useState<NewIntent>(NewIntent.NO_DRAFT);

  const { user, isAuthenticated } = useAuth0();
  const [sortOption, setSortOption] = useState<string>("modifiedDate");

  const [templateIndex, setTemplateIndex] = useState<number>(-1);
  const [templates, setTemplates] = useState<DocumentExtended[]>([]);
  const { isOpen: isOpenPreviewPanel, onOpen: onOpenPreviewPanel, onClose: onClosePreviewPanel } = useDisclosure();

  useEffect(() => {
    async function getPapers() {
      try {
        const papers = await PaperService.getPapers();
        setPapers(papers);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    getPapers();
  }, [])

  useEffect(() => {
    async function getTemplates() {
      try {
        const newTemplates: DocumentExtended[] = await TemplateService.getAllTemplates();
        setTemplates(newTemplates);

        for (const template of newTemplates) {
          if (!template.thumbnailS3Path) {
            continue;
          }

          const thumbnail = await DocumentService.getThumbnailById(template.id).catch(() => null);
          if (!thumbnail) {
            continue;
          }

          template.thumbnailUrl = URL.createObjectURL(thumbnail);
        }
        setTemplates(newTemplates);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    getTemplates();
  }, [])

  const continueEditing = (paper_id) => {
    console.log('continue to editing ', paper_id);
    navigate(`/editor/${paper_id}`);
  }

  const deletePaper = async (paperId) => {
    const originalPapers = papers;
    try {
      const newPapers = papers.filter(paper => paper.id !== paperId);
      setPapers(newPapers);
      await PaperService.deletePaper(paperId);
      toast({
        title: `Ppaer has been deleted.`,
        description: ``,
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      new ClientError(error).toast();
      setPapers(originalPapers);
    }
  }

  const viewPaper = async (paperId: string) => {
    const filteredPaper = papers.find(paper => paper.id === paperId);
    setSelectedPaper(filteredPaper);
    onPaperViewerOpen();
  }

  const newDocumentOnClick = () => {
    onNewDocumentOpen();
    setNewIntent(NewIntent.NO_DRAFT);
  }

  const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);

    const sortedPapers = [...papers].sort((a, b) => {
      if (selectedOption === "updatedAt") {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      } else if (selectedOption === "createdAt") {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (selectedOption === "name") {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

    setPapers(sortedPapers);
  };

  return (
    <Flex flexDir={'row'} w={'full'} h={'full'} className='main-bg'>
      <SideNav/>
      <Flex flexDir={'column'} w={'full'} h={'full'}>
        <Flex flexDir={'row'} w={`calc(100% - ${pxToRem(154)})`} ml={pxToRem(77)} h={'90px'} justify="space-between" align='center'>
          <SearchBar />
          <HStack>
            <Box mr={'30px'}>
              <LangSelectV2/>
            </Box>
            {isAuthenticated && user && (
              <UserMenu user={user}/>
            )}
          </HStack>
        </Flex>
        <Container minW={`calc(100% - ${pxToRem(154)})`} overflow={'auto'} pl={0} pr={'2rem'}>
          <Flex flexDir={'column'}  w={'full'}>
            <Box bgColor={'rgba(255, 255, 255, 0.45)'} borderRadius={pxToRem(24)} w={'full'} border={'1px solid white'}>
              <Heading p={5} size={'md'} mb={3}>Start a new document</Heading>
              <Wrap my={5} overflow={'auto'} w={'full'} p={5} pt={0} display={'flex'}>
                <WrapItem display={'flex'} flexDir={'column'} px={6}>
                  <Card  w={pxToRem(136)} h={pxToRem(172)} cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }}
                        border={'1px solid #d3d7e2'}>
                    <CardBody onClick={newDocumentOnClick}>
                      <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                        <Box>
                          <Image
                            w={pxToRem(48)}
                            objectFit='cover'
                            src='/static/doenba-ico-blue.png'
                            alt='Doenba'
                          />
                        </Box>
                      </Center>
                    </CardBody>
                  </Card>
                  <Box mt={2} w={'full'} textAlign={'center'}>
                    <Text fontSize={'1rem'} fontFamily={'Lato'}>
                      Create
                    </Text>
                  </Box>
                </WrapItem>
                {papers.length > 0 && templates.map((template, i) => (
                  <WrapItem key={template.id} display={'flex'} flexDir={'column'} px={6}>
                    <Card  cursor={'pointer'} _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }}
                          border={'1px solid #d3d7e2'} borderRadius={pxToRem(10)}>
                      <CardBody p={0} onClick={() => {
                        setTemplateIndex(i);
                        onOpenPreviewPanel()
                      }}>
                        <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                          <Image
                            w={pxToRem(136)} h={pxToRem(172)}
                            objectFit='contain'
                            src={template.thumbnailUrl || '/static/paper_preview.png'}
                            alt='template draft'
                          />
                        </Center>
                      </CardBody>
                    </Card>
                    <Box mt={2} w={'full'} textAlign={'center'}>
                      <Text fontSize={'1rem'} fontFamily={'Lato'}>
                        {template.name}
                      </Text>
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
              {papers.length <= 0 &&
                  <Wrap borderTop={'1px solid #d3d7e2'} my={5} overflow={'auto'} w={'full'} p={5}>
                      <WrapItem>
                          <HStack spacing='21px'>
                            {templates.map((template, i) => (
                              <Card key={template.id} w={'150px'} h={'200px'} cursor={'pointer'}
                                    _hover={{ boxShadow: '2px 2px 5px #d3d7e2' }} border={'1px solid #d3d7e2'}>
                                <CardBody onClick={() => {
                                  setTemplateIndex(i);
                                  onOpenPreviewPanel()
                                }}>
                                  <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                    <Image
                                      w={'55px'}
                                      objectFit='cover'
                                      src={BASE_URL + template['snapshotS3Path']}
                                      alt='template draft'
                                    />
                                  </Center>
                                </CardBody>
                                <Box mt={2} w={'full'} textAlign={'center'}>
                                  <Text fontSize={14}>
                                    {template.name}
                                  </Text>
                                </Box>
                              </Card>
                            ))}
                          </HStack>
                      </WrapItem>
                  </Wrap>
              }
            </Box>
            <CreatePaper newIntent={newIntent} setNewIntent={setNewIntent} isNewDocumentOpen={isNewDocumentOpen}
              onNewDocumentClose={onNewDocumentClose}/>
            {papers.length > 0 &&
                <Box mt={5} bgColor={'rgba(255, 255, 255, 0.45)'} pt={'1rem'} px={'1.5rem'} borderRadius={'25px'} w={'full'}
                     border={'1px solid white'}>
                    <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} pb={3}>
                        <Heading fontFamily={'Lato'} fontSize={pxToRem(24)} fontWeight={'bold'} flex={'1 auto'}>Recent Documents</Heading>
                        <DoenbaSelect
                            options={[
                              { label: 'Modified Date', value: 'updatedAt' },
                              { label: 'Opened Date', value: 'createdAt' },
                              { label: 'Title', value: 'name' },
                            ]}
                            value={sortOption}
                            onChange={handleSortingChange}
                            variant="outline"
                            borderColor="gray.300"
                            hoverBorderColor="gray.400"
                        />
                    </Flex>
                    <Wrap mt={5} w={'full'} minH={'35vh'}>
                      {papers.map(paper => (
                        <WrapItem key={paper.id} px={4} pb={6}>
                          <PaperCard
                            paper={paper}
                            viewPaper={() => viewPaper(paper.id)}
                            continueEditing={() => continueEditing(paper.id)}
                            discardPaper={() => deletePaper(paper.id)}
                          />
                        </WrapItem>
                      ))}
                    </Wrap>
                </Box>
            }
          </Flex>
        </Container>
      </Flex>

      {selectedPaper &&
          <Modal isOpen={isPaperViewerOpen} onClose={onPaperViewerClose} size={'full'}>
              <ModalOverlay/>
              <ModalContent w={'90%'} h={'90vh'}>
                  <ModalHeader>{selectedPaper.name}</ModalHeader>
                  <ModalCloseButton _focusVisible={{ 'box-shadow': 'none' }}
                                    bg={'#FFFFFF'}
                                    width={pxToRem(24)}
                                    height={pxToRem(24)}
                                    _hover={{ bg: '#D3D7E2' }}
                                    color={'#8E9ABC'}
                                    top={pxToRem(20)}
                                    right={pxToRem(20)}
                                    fontSize={'8px'}
                                    borderRadius={'50%'} />
                  <ModalBody overflow={'auto'}>
                    {JSON.stringify(selectedPaper.content)}
                  </ModalBody>

                  <ModalFooter>
                      <Button colorScheme='yellow' size={'sm'} leftIcon={<EditIcon/>} mr={3}
                              onClick={() => continueEditing(selectedPaper.id)}>
                          Edit
                      </Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
      }
      <Modal
        isOpen={isOpenPreviewPanel}
        onClose={onClosePreviewPanel}
        scrollBehavior="inside"
      >
        <ModalOverlay background={'whiteAlpha.200'} backdropFilter={'blur(2px)'}/>
        <ModalContent minW="672px" minHeight="880px" top="-30px">
          <ModalCloseButton _focusVisible={{ 'box-shadow': 'none' }}
                            bg={'#FFFFFF'}
                            width={pxToRem(24)}
                            height={pxToRem(24)}
                            _hover={{ bg: '#D3D7E2' }}
                            color={'#8E9ABC'}
                            top={pxToRem(20)}
                            right={pxToRem(20)}
                            fontSize={'8px'}
                            borderRadius={'50%'} />
          <ModalBody p={5}>
            <PreviewModalPanel templateIndex={templateIndex} templates={templates}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Writer;
